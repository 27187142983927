export const UNDERSTOCKANALYSISCOLUMNS = [
  {
    field: "line",
    headerName: "Line",
    width: 70,
    type: "number",
    align: "right",
  },
  {
    field: "brand",
    headerName: "Brand",
    width: 150,
  },
  {
    field: "itemClass",
    headerName: "Item Class",
    width: 150,
  },
  {
    field: "baseNumber",
    headerName: "Base Number",
    width: 150,
  },
  {
    field: "item",
    headerName: "Item Number",
    width: 150,
  },
  {
    field: "itemInterchange",
    headerName: "Item Interchange",
    width: 150,
  },
  {
    field: "forecasttotal",
    headerName: "Forecast Total 12 Months",
    width: 180,
    type: "number",
    align: "center",
  },
  {
    field: "available",
    headerName: "Available",
    width: 90,
    type: "number",
  },
  {
    field: "onOrder",
    headerName: "On Order",
    width: 90,
    type: "number",
  },
  {
    field: "TO_OnOrder",
    headerName: "TO On Order",
    width: 120,
    type: "number",
  },
  {
    field: "PO_OnOrder",
    headerName: "PO On Order",
    width: 120,
    type: "number",
    align: "center",
  },

  {
    field: "understock",
    headerName: "Understock",
    width: 100,
    type: "number",
    align: "center",
  },
  {
    field: "Understock + On Order",
    headerName: "Understock + On Order",
    width: 180,
    type: "number",
    align: "center",
    valueGetter: (params) => params.row.onOrder + params.row.understock,
  },
  {
    field: "qty",
    headerName: "Quantity To Transfer",
    width: 150,
    type: "number",
  },
  {
    field: "monthsOfSupply",
    headerName: "Months Of Supply",
    width: 150,
    type: "number",
    align: "center",
  },
  {
    field: "fromLocation",
    headerName: "From Location",
    width: 150,
  },
  {
    field: "overstock",
    headerName: "Overstock",
    width: 100,
    type: "number",
    align: "center",
  },
  {
    field: "fromPlanningGroup",
    headerName: "From Planning Group",
    width: 150,
  },
  {
    field: "toLocation",
    headerName: "To Location",
    width: 150,
  },
  {
    field: "toPlanningGroup",
    headerName: "To Planning Group",
    width: 150,
  },
  { field: "action", headerName: "Action", width: 150 },
  {
    field: "lastSODate",
    headerName: "Last SO Date",
    width: 150,
    valueGetter: (params) =>
      params.row.lastSODate
        ? new Date(params.row.lastSODate).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "POExpectedReceiptDetails",
    headerName: "PO Expected Receipt Details",
    width: 200,
    align: "center",
  },
  {
    field: "TOExpectedReceiptDetails",
    headerName: "TO Expected Receipt Details",
    width: 200,
    align: "center",
  },
  {
    field: "lastTOReceiptDate",
    headerName: "Last TO Receipt Date",
    width: 200,
    align: "center",
    valueGetter: (params) =>
      params.row.lastTOReceiptDate
        ? new Date(params.row.lastTOReceiptDate).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "lastPOReceiptDate",
    headerName: "Last PO Receipt Date",
    width: 200,
    align: "center",
    valueGetter: (params) =>
      params.row.lastPOReceiptDate
        ? new Date(params.row.lastPOReceiptDate).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "TOFirstExpectedReceiptDate",
    headerName: "TO First Expected Receipt Date",
    width: 200,
    align: "center",
    valueGetter: (params) =>
      params.row.TOFirstExpectedReceiptDate
        ? new Date(params.row.TOFirstExpectedReceiptDate).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "TOLastExpectedReceiptDate",
    headerName: "TO Last Expected Receipt Date",
    width: 200,
    align: "center",
    valueGetter: (params) =>
      params.row.TOLastExpectedReceiptDate
        ? new Date(params.row.TOLastExpectedReceiptDate).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "POFirstExpectedReceiptDate",
    headerName: "PO First Expected Receipt Date",
    width: 200,
    align: "center",
    valueGetter: (params) =>
      params.row.POFirstExpectedReceiptDate
        ? new Date(params.row.POFirstExpectedReceiptDate).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "POLastExpectedReceiptDate",
    headerName: "PO Last Expected Receipt Date",
    width: 200,
    align: "center",
    valueGetter: (params) =>
      params.row.POLastExpectedReceiptDate
        ? new Date(params.row.POLastExpectedReceiptDate).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "customerReleaseDate",
    headerName: "Customer Release Date",
    width: 200,
    align: "center",
    valueGetter: (params) =>
      params.row.customerReleaseDate
        ? new Date(params.row.customerReleaseDate).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "newpart",
    headerName: "New Part",
    width: 150,
    valueGetter: (params) => {
      if (params.row.newpart === undefined) return "";
      if (params.row.newpart === false) return "NO";
      if (params.row.newpart) return "YES";
    },
  },
  {
    field: "boxsize",
    headerName: "Box Size",
    width: 80,
  },
];

export const OVERSTOCKANALYSISCOLUMNS = [
  {
    field: "itemNumber",
    headerName: "Item Number",
    width: 150,
    align: "center",
  },
  {
    field: "baseNumber",
    headerName: "Base Number",
    width: 150,
    align: "center",
  },
  {
    field: "ItemClass",
    headerName: "Item Class",
    width: 150,
    align: "center",
  },
  {
    field: "Brand",
    headerName: "Brand",
    width: 150,
    align: "center",
  },
  {
    field: "dcLocation",
    headerName: "DC Location",
    width: 150,
    align: "center",
  },
  {
    field: "Location",
    headerName: "Location",
    width: 150,
    align: "center",
  },
  {
    field: "planninggroup",
    headerName: "Planning Group",
    width: 150,
    align: "center",
  },
  {
    field: "last_so_date",
    headerName: "Last SO Date",
    width: 150,
    align: "center",
    valueGetter: (params) =>
      params.row.last_so_date
        ? new Date(params.row.last_so_date).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "forecasttotal",
    headerName: "Stock Level 12 Months",
    width: 180,
    align: "center",
    type: "number",
  },
  {
    field: "daysofsupply",
    headerName: "Days of Supply",
    width: 150,
    align: "center",
    type: "number",
  },
  {
    field: "overstock",
    headerName: "Overstock",
    width: 150,
    align: "center",
    type: "number",
  },
  {
    field: "needTrakMotiveCA",
    headerName: "Need TrakMotive CA",
    width: 150,
    align: "center",
    type: "number",
  },
  // {
  //   field: "needTrakMotiveNC",
  //   headerName: "Need Trakmotive NC",
  //   width: 150,
  //   align: "center",
  //   type: "number",
  // },
  // {
  //   field: "needAutozone",
  //   headerName: "Need Autozone",
  //   width: 150,
  //   align: "center",
  //   type: "number",
  // },
  {
    field: "needOreilly",
    headerName: "Need O'Reilly",
    width: 150,
    align: "center",
    type: "number",
  },
  {
    field: "needNapa",
    headerName: "Need NAPA",
    width: 150,
    align: "center",
    type: "number",
  },
  {
    field: "needBBNC",
    headerName: "Need BBNC",
    width: 150,
    align: "center",
    type: "number",
  },
  {
    field: "totalUnderstock",
    headerName: "Total Understock",
    width: 150,
    align: "center",
    type: "number",
  },
  {
    field: "netOverstock",
    headerName: "Net Overstock",
    width: 150,
    align: "center",
    type: "number",
  },
  {
    field: "customer_releasedate",
    headerName: "Customer Release Date",
    width: 200,
    align: "center",
    valueGetter: (params) =>
      params.row.customer_releasedate
        ? new Date(params.row.customer_releasedate).toLocaleDateString()
        : "",
    sortComparator: customDateSortComparator,
  },
  {
    field: "newpart",
    headerName: "New Part",
    width: 150,
    valueGetter: (params) => {
      if (params.row.newpart === undefined) return "";
      if (params.row.newpart === false) return "NO";
      if (params.row.newpart) return "YES";
    },
  },
];

export const ALLITEMSCOLUMNS = [
  {
    field: "Item Number",
    headerName: "Item Number",
    width: 120,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Base Number",
    headerName: "Base Number",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "ItemClass",
    headerName: "Item Class",
    width: 180,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Brand",
    headerName: "Brand",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Location",
    headerName: "Location",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Overstock",
    headerName: "Overstock Qty",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Understock",
    headerName: "Understock Qty",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Available",
    headerName: "Available Qty",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Days of Supply",
    headerName: "Days of Supply",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => Math.floor(params.row["Days of Supply"]),
  },
  {
    field: "Months of Supply",
    headerName: "Months Of Supply",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => Math.floor(params.row["Months of Supply"]),
  },
  {
    field: "po_onorder",
    headerName: "PO On Order",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params.row.po_onorder || 0,
    type: "number",
  },
  {
    field: "to_onorder",
    headerName: "TO On Odrer",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params.row.to_onorder || 0,
    type: "number",
  },
  {
    field: "onhand",
    headerName: "On Hand",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "committed",
    headerName: "Committed",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "planninggroup",
    headerName: "Planning Group",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "currentrolling12",
    headerName: "Current Rolling 12 (history)",
    width: 200,
    align: "center",
    headerAlign: "center",
    type: "number",
  },
  {
    field: "historictotal",
    headerName: "History Total 12 Months",
    width: 180,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "forecasttotal",
    headerName: "Forecast Total 12 Months",
    width: 180,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) =>
      params.row.forecasttotal === 0 ? 0 : params.row.forecasttotal.toFixed(2),
  },
  {
    field: "last_so_date",
    headerName: "Last SO Date",
    width: 150,
    type: "date",
    align: "center",
    headerAlign: "center",
    valueGetter: (params) =>
      params.row.last_so_date ? new Date(params.row.last_so_date) : null,
  },
  {
    field: "last_to_receipt_date",
    headerName: "Last TO Receipt Date",
    width: 160,
    align: "center",
    headerAlign: "center",
    type: "date",
    valueGetter: (params) =>
      params.row.last_to_receipt_date
        ? new Date(params.row.last_to_receipt_date)
        : null,
  },
  {
    field: "last_po_receipt_date",
    headerName: "Last PO Receipt Date",
    width: 160,
    align: "center",
    headerAlign: "center",
    type: "date",
    valueGetter: (params) =>
      params.row.last_po_receipt_date
        ? new Date(params.row.last_po_receipt_date)
        : null,
  },
  {
    field: "po_firstexpectedreceiptdate",
    headerName: "PO First Expected Receipt Date",
    width: 250,
    align: "center",
    headerAlign: "center",
    type: "date",
    valueGetter: (params) =>
      params.row.po_firstexpectedreceiptdate
        ? new Date(params.row.po_firstexpectedreceiptdate)
        : null,
  },
  {
    field: "po_lastexpectedreceiptdate",
    headerName: "PO Last Expected Receipt Date",
    width: 210,
    align: "center",
    headerAlign: "center",
    type: "date",
    valueGetter: (params) =>
      params.row.po_lastexpectedreceiptdate
        ? new Date(params.row.po_lastexpectedreceiptdate)
        : null,
  },
  {
    field: "po_expectedreceipdetails",
    headerName: "PO Expected Receipt Details",
    width: 250,
    align: "center",
    headerAlign: "center",
    sortable: false,
    valueGetter: (params) =>
      params.row.po_expectedreceipdetails
        ? params.row.po_expectedreceipdetails
        : "",
  },
  {
    field: "to_firstexpectedreceiptdate",
    headerName: "TO First Expected Receipt Date",
    width: 250,
    align: "center",
    headerAlign: "center",
    type: "date",
    valueGetter: (params) =>
      params.row.to_firstexpectedreceiptdate
        ? new Date(params.row.to_firstexpectedreceiptdate)
        : null,
  },
  {
    field: "to_lastexpectedreceiptdate",
    headerName: "TO Last Expected Receipt Date",
    width: 250,
    align: "center",
    headerAlign: "center",
    type: "date",
    valueGetter: (params) =>
      params.row.to_lastexpectedreceiptdate
        ? new Date(params.row.to_lastexpectedreceiptdate)
        : null,
  },
  {
    field: "to_expectedreceipdetails",
    headerName: "TO Expected Receipt Details",
    width: 250,
    align: "center",
    headerAlign: "center",
    sortable: false,
    valueGetter: (params) =>
      params.row.to_expectedreceipdetails
        ? params.row.to_expectedreceipdetails
        : "",
  },
  {
    field: "customer_releasedate",
    headerName: "Customer Release Date",
    width: 180,
    align: "center",
    headerAlign: "center",
    type: "date",
    valueGetter: (params) =>
      params.row.customer_releasedate
        ? new Date(params.row.customer_releasedate)
        : null,
  },
  {
    field: "newpart",
    headerName: "New Part",
    width: 100,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => (params.row.newpart ? "YES" : "NO"),
  },
];

export const RANKINGPROCESSCOLUMNS = [
  {
    field: "line",
    headerName: "Line",
    align: "center",
    headerAlign: "center",
  },
  {
    field: "itemNumber",
    headerName: "Item Number",
    align: "center",
    width: 200,
    headerAlign: "center",
  },
  {
    field: "Location",
    headerName: "Location",
    width: 130,
    sortable: false,
  },
  {
    field: "currentrolling12",
    headerName: "Current Rolling 12",
    width: 160,
    align: "center",
    type: "number",
  },
  {
    field: "ItemClass",
    headerName: "Item Class",
    width: 150,
    sortable: false,
  },
  {
    field: "locationClass",
    headerName: "Location & Class",
    width: 250,
    sortable: false,
  },
  {
    field: "totalSalesByLocationClass",
    headerName: "Total Sales By Location And Class",
    width: 250,
    type: "number",
    align: "center",
  },
  {
    field: "percentageOfSalesByLocationClass",
    headerName: "Percentage of Sales",
    width: 200,
    type: "number",
    valueGetter: (params) =>
      Number(params.row.percentageOfSalesByLocationClass).toFixed(9),
    align: "right",
  },
  {
    field: "locationClassRunningTotal",
    headerName: "Running Total",
    width: 200,
    type: "number",
    valueGetter: (params) =>
      Number(params.row.locationClassRunningTotal).toFixed(9),
    align: "right",
  },
  {
    field: "rank",
    headerName: "Location and Class Classification",
    width: 230,
    align: "center",
  },
];

export const REPLENISHMENTANALYSISCOLUMNS = [
  {
    field: "line",
    headerName: "Line",
    align: "center",
    type: "number",
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "Item Class",
    headerName: "Item Class",
    align: "center",
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "Item Number",
    headerName: "Item Number",
    align: "center",
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "Item Interchange",
    headerName: "Item Interchange",
    align: "center",
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "brand",
    headerName: "Brand",
    align: "center",
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "Qty",
    headerName: "Quantity",
    align: "center",
    type: "number",
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "From Location",
    headerName: "From Location",
    align: "center",
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "To Location",
    headerName: "To Location",
    align: "center",
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "action",
    headerName: "Action",
    align: "center",
    flex: 1,
    headerAlign: "center",
  },
  {
    field: "daysOfSupply",
    headerName: "Days Of Supply",
    align: "center",
    flex: 1,
    headerAlign: "center",
  },
];

export const RECEIVINGCOLUMNS = [
  {
    field: "container",
    headerName: "Container",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "expectedarrivaldate",
    headerName: "Expected Arrival Date",
    type: "date",
    width: 200,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      return new Date(params.row.expectedarrivaldate);
    },
  },
  {
    field: "item",
    headerName: "Item",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "itembasenumber",
    headerName: "Base Number",
    width: 120,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "ponumber",
    headerName: "PO Number",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "polocation",
    headerName: "PO Location",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "dclocation",
    headerName: "DC Location",
    width: 100,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "palletnumber",
    headerName: "Pallet Number",
    width: 120,
    align: "center",
    headerAlign: "center",
    type: "number",
  },
  {
    field: "qtyshipped",
    headerName: "QTY Shipped",
    width: 120,
    align: "center",
    headerAlign: "center",
    type: "number",
  },
  {
    field: "shippingadvice",
    headerName: "Shipping Advice",
    width: 120,
    align: "center",
    headerAlign: "center",
  },
];

export const LOSTSALESCOLUMS = [
  {
    field: "subsidiary",
    headerName: "Subsidiary",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Item",
    headerName: "Item",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Item Class",
    headerName: "Item Class",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Planning Location",
    headerName: "Planning Location",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Display Name",
    headerName: "Display Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "# of Sales Orders",
    headerName: "Number of Sales Orders",
    flex: 1,
    align: "center",
    headerAlign: "center",
    type: "number",
  },
  {
    field: "Lost Sales Qty",
    headerName: "Lost Sales Qty",
    flex: 1,
    align: "center",
    headerAlign: "center",
    type: "number",
  },
];

//helper date sort function that handles blank values
function customDateSortComparator(a, b) {
  // Handle empty values
  if (a === "" && b === "") return 0;
  if (a === "") return 1;
  if (b === "") return -1;

  const dateA = new Date(a);
  const dateB = new Date(b);

  if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are not valid dates
  if (isNaN(dateA)) return 1; // Handle invalid date format
  if (isNaN(dateB)) return -1; // Handle invalid date format

  return dateA - dateB; // Compare dates by subtracting them
}
