import { useState } from "react";
import { useSelector } from "react-redux";
import { CheckCircle, Error, Search, Warning } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../FlexWrapper";

const PackProcessAllScansView = ({ heightOffset = "210px" }) => {
  const { scans } = useSelector((state) => state.packProcessV2);

  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState("");

  // Filter scans based on the search term and the selected filter
  const filteredScans = scans
    .filter(({ item, upc, action }) => {
      // Check if the item, action or upc matches the search term
      return (
        `${item}`?.toUpperCase()?.includes(search.toUpperCase()) ||
        `${upc}`?.toUpperCase()?.includes(search.toUpperCase()) ||
        `${action}`?.toUpperCase()?.includes(search.toUpperCase())
      );
    })
    .filter(({ scantype, deleted }) =>
      // Apply the filter for "all", "success", or "error"
      {
        if (filter === "deleted") {
          return deleted;
        } else {
          return filter === "all" || (scantype === filter && !deleted);
        }
      }
    )
    .reverse();

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Box>
      <FlexWrapper justifyContent="space-between" alignItems="center">
        <Typography>Total Actions: {filteredScans.length}</Typography>

        <FormControl size="small" sx={{ width: "150px", pb: "5px" }}>
          <InputLabel id="scan-filter-label">Scan Type</InputLabel>
          <Select
            sx={{ height: "33px" }}
            size="small"
            labelId="scan-filter-label"
            id="scan-filter"
            value={filter}
            label="Scan Type"
            onChange={handleFilterChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="success">Success</MenuItem>
            <MenuItem value="error">Error</MenuItem>
            <MenuItem value="deleted">Deleted</MenuItem>
          </Select>
        </FormControl>
      </FlexWrapper>

      <TextField
        disabled={scans.length === 0}
        size="small"
        variant="outlined"
        fullWidth
        placeholder="Search scans by item..."
        value={search}
        onChange={(event) => {
          const itemSplit = event.target.value?.toUpperCase()?.split("*");
          const item = itemSplit[0];
          setSearch(item);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          overflowY: "scroll",
          height: `calc(100vh - ${heightOffset})`,
        }}
      >
        {filteredScans.length ? (
          <Stack pb={1} spacing={0.5}>
            {filteredScans.map(
              (
                {
                  type,
                  action,
                  scantype,
                  scanqty,
                  pelletnumber,
                  item,
                  error,
                  createdAt,
                  deleted,
                  boxnumber,
                },
                index
              ) => (
                <Card
                  key={createdAt} // or use another unique identifier like _id
                  sx={{
                    backgroundColor: deleted
                      ? "#FFF4E5" // Yellowish background for deleted items
                      : scantype === "success"
                      ? "#E6F4EA"
                      : "#FDECEA",
                    borderLeft: `5px solid ${
                      deleted
                        ? "#FFB74D" // Orange border for deleted items
                        : scantype === "success"
                        ? "#4CAF50"
                        : "#F44336"
                    }`,
                  }}
                >
                  <CardContent>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <Icon
                          component={
                            deleted
                              ? Warning // Use Warning icon for deleted items
                              : scantype === "success"
                              ? CheckCircle
                              : Error
                          }
                          sx={{
                            color: deleted
                              ? "#FFB74D" // Orange color for deleted items
                              : scantype === "success"
                              ? "#4CAF50"
                              : "#F44336",
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        {item && (
                          <Typography variant="h6" gutterBottom>
                            {item} {index === 0 ? "(Latest Scan)" : ""}
                          </Typography>
                        )}
                        {type === "action" && action && (
                          <Typography variant="h6" gutterBottom>
                            {action} {index === 0 ? "(Latest)" : ""}
                          </Typography>
                        )}
                        {scanqty && pelletnumber && (
                          <Typography variant="body2" color="textSecondary">
                            Qty Entered: {scanqty} | Pallet Number:{" "}
                            {pelletnumber}
                          </Typography>
                        )}
                        {boxnumber && (
                          <Typography variant="body2" color="textSecondary">
                            Box: {boxnumber}
                          </Typography>
                        )}
                        <Typography variant="body2" color="textSecondary">
                          {new Date(createdAt).toLocaleString()}
                        </Typography>
                        {scantype === "error" && (
                          <Typography variant="body2" color="error">
                            Error: {error}
                          </Typography>
                        )}
                        {deleted && (
                          <Typography variant="body2" color="warning.main">
                            This item was deleted
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )
            )}
          </Stack>
        ) : (
          <Typography textAlign="center" variant="h6" pt={3}>
            No Scans To Display
          </Typography>
        )}
      </Box>
    </Box>
  );
};
export default PackProcessAllScansView;
