import { useRef, useState } from "react";
import { handlePrintContentLabels } from "../../../../../utils/printer-functions/printContentLabels";
import { useSelector } from "react-redux";
import { printGeneralV2 } from "../../../../../utils/printer-functions/printGeneralV2";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../../../general/GeneralModalV3";
import Loading from "../../../../Loading";
import { useGeneralAlert } from "../../../../../hooks/useGeneralAlert";

const PrintBoxLabel = ({ openComponent, getOrderFn }) => {
  const [order, setOrder] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [palletOptions, setPalletOptions] = useState([]);
  const [pallet, setPallet] = useState("");
  const [box, setBox] = useState("");
  const [packData, setPackData] = useState(null);

  const palletBoxes =
    palletOptions?.length && packData?.packdetails?.length
      ? packData.packdetails.find(
          (p) => p?.palletId === parseInt(pallet) // Replace with the correct variable
        )?.boxes
      : null;

  const inputRef = useRef(null);
  const { currentPrinter } = useSelector((state) => state.printers);
  const { openAlert } = useGeneralAlert();

  //handles input focus (hides keyboard when focused)
  const handleItemRefFocus = () => {
    if (inputRef.current !== null) {
      inputRef.current.readOnly = true;
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.readOnly = false;
      });
    }
  };

  const handlePackDataSubmit = async (event) => {
    event.preventDefault();
    if (error) {
      setError("");
    }
    setLoading("Loading Order...");
    try {
      let data = null;
      if (getOrderFn && typeof getOrderFn === "function") {
        data = await getOrderFn(order);
      }

      const orderData = data[0];

      const palletOptions = orderData?.packdetails.map(
        (palletObj) => palletObj.palletId
      );

      setPackData(orderData);
      setPalletOptions(palletOptions);
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  const printBoxLabel = async (handleClose) => {
    openAlert({
      type: "success",
      message: "Printing Box Label...",
      duration: 3000,
    });

    const { createdfrom, ponumber } = packData;

    if (box !== "all") {
      const palletBox = palletBoxes?.find((b) => b?.boxId === box);
      //new version 300dpi
      const templateHeader = `^XA
^MMT^PW1200^LL1800^LS0^POI^LH0,0    
^FT0,125^FB1175,1,0,C,0^A0N,115^FDCONTENTS^FS
^FT0,325^FB1175,1,0,C,0^A0N,225^FD${createdfrom}^FS
^FT0,443^FB1175,1,0,C,0^A0N,85^FDPO#: ${ponumber}^FS
^FT15,554^FB450,1,0,C,0^A0N,75^FDPALLET: ${
        parseInt(pallet) < 9 ? `0${parseInt(pallet)}` : parseInt(pallet)
      }^FS
^FT554,554^FB450,1,0,R,0^A0N,75^FDBOX: ${
        parseInt(box) < 9 ? `0${parseInt(box)}` : parseInt(box)
      }^FS
^FT0,591^GB1212,1,6,B^FS
^FT0,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT185,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS
^FT628,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT813,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS`;

      await handlePrintContentLabels({
        templateHeader,
        currentPrinter,
        startingArr: palletBox?.items,
        title: `Print Content Labels for Box ${box} Pallet ${pallet}-${createdfrom}`,
      });
    } else {
      let finalTemplate = "";

      for (const palletBox of palletBoxes) {
        const templateHeader = `^XA
^MMT^PW1200^LL1800^LS0^POI^LH0,0    
^FT0,125^FB1175,1,0,C,0^A0N,115^FDCONTENTS^FS
^FT0,325^FB1175,1,0,C,0^A0N,225^FD${createdfrom}^FS
^FT0,443^FB1175,1,0,C,0^A0N,85^FDPO#: ${ponumber}^FS
^FT15,554^FB450,1,0,C,0^A0N,75^FDPALLET: ${
          parseInt(pallet) < 9 ? `0${parseInt(pallet)}` : parseInt(pallet)
        }^FS
^FT554,554^FB450,1,0,R,0^A0N,75^FDBOX: ${
          parseInt(palletBox?.boxId) < 9
            ? `0${parseInt(palletBox?.boxId)}`
            : parseInt(palletBox?.boxId)
        }^FS
^FT0,591^GB1212,1,6,B^FS
^FT0,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT185,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS
^FT628,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT813,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS`;

        const zpl = await handlePrintContentLabels({
          templateHeader,
          currentPrinter,
          startingArr: palletBox.items,
          title: `Print Content Labels for Box ${palletBox?.boxId} Pallet ${pallet}-${createdfrom}`,
          type: "zpl",
        });

        finalTemplate += zpl;
      }
      //print template
      await printGeneralV2({
        template: finalTemplate,
        currentPrinter,
        title: `Print Content Labels for all boxes in Pallet ${pallet} `,
      });
    }

    //exit modal
    handleClose();
  };

  //clears state
  const handleModalClose = () => {
    setOrder("");
    setError("");
    setLoading("");
    setPackData(null);
    setPallet("");
    setPalletOptions([]);
    setBox("");
  };

  return (
    <GeneralModalV3
      keepMounted={true}
      onClose={handleModalClose}
      onOpen={() => handleItemRefFocus()}
      openComponent={openComponent}
      disableClick={Boolean(loading)}
    >
      {(handleClose) =>
        Boolean(loading) ? (
          <Loading height="250px" message={loading} />
        ) : (
          <Box p={2}>
            <Typography variant="h5" gutterBottom textAlign="center" pb={1}>
              Search Order
            </Typography>

            <form onSubmit={handlePackDataSubmit}>
              <TextField
                disabled={packData !== null}
                required
                autoFocus
                fullWidth
                label="Enter Order Number"
                value={order}
                onChange={(event) =>
                  setOrder(event.target.value.toUpperCase().trim())
                }
                inputProps={{ autoComplete: "off" }}
                inputRef={inputRef}
                error={Boolean(error)}
                helperText={error}
              />

              {palletOptions.length === 0 && !packData && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ my: 2 }}
                >
                  Search Order
                </Button>
              )}

              {palletOptions.length !== 0 && packData && (
                <>
                  <FormControl
                    error={Boolean(error)}
                    required
                    sx={{ mt: 2 }}
                    fullWidth
                  >
                    <InputLabel>Pallet Number</InputLabel>
                    <Select
                      label="Pallet Number"
                      value={pallet}
                      onChange={(event) => setPallet(event.target.value)}
                    >
                      {palletOptions.map((pallet) => {
                        return (
                          <MenuItem key={pallet} value={pallet}>
                            {pallet}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {pallet && !palletBoxes?.length ? (
                    <Typography variant="caption" color="error">
                      No Boxes Avaiable for this pallet
                    </Typography>
                  ) : null}
                </>
              )}

              {palletOptions.length !== 0 &&
                packData &&
                Boolean(palletBoxes?.length) && (
                  <>
                    <FormControl
                      error={Boolean(error)}
                      required
                      sx={{ mt: 2 }}
                      fullWidth
                    >
                      <InputLabel>Box Number</InputLabel>
                      <Select
                        label="Box Number"
                        value={box}
                        onChange={(event) => setBox(event.target.value)}
                      >
                        <MenuItem key="all" value="all">
                          All
                        </MenuItem>
                        {palletBoxes?.map((b) => {
                          return (
                            <MenuItem key={b?.boxId} value={b?.boxId}>
                              {b?.boxId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <Button
                      disabled={!palletBoxes?.length}
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ my: 2 }}
                      onClick={() => printBoxLabel(handleClose)}
                    >
                      Print Box Label
                    </Button>
                  </>
                )}
            </form>
          </Box>
        )
      }
    </GeneralModalV3>
  );
};

export default PrintBoxLabel;
