import { useState } from "react";
import { CheckCircle, Print, Replay, Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import {
  printToteSingleItemLabel,
  printToteSummaryLabel,
} from "../../../utils/printer-functions/printToteItems";
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import FlexWrapper from "../../../components/FlexWrapper";

const PrintToteItemLabels = () => {
  const [itemInput, setItemInput] = useState("");
  const [toteInput, setToteInput] = useState("");
  const [tote, setTote] = useState(null);
  const [error, setError] = useState("");

  const { currentPrinter } = useSelector((state) => state.printers);
  const { currentSubsidiary, username, currentLocation } = useSelector(
    (state) => state.user
  );
  const { openAlert } = useGeneralAlert();
  //sets tote
  const handleToteLabelSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(`totes/${toteInput}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data?.status !== "pending") {
        throw new Error(`Incorrect order status: ${response.data?.status}`);
      }

      if (response?.data) {
        setError("");
        setTote(response.data);
      }
    } catch (error) {
      setError(error?.response?.data?.msg || error.message);
    }
  };
  //prints item
  const handleToteItemSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setItemInput("");
    try {
      let finalBrand = tote?.memo;

      if (!tote) {
        throw new Error("Tote not available, please refresh");
      }

      const foundItem = tote?.items?.find(
        (item) =>
          item?.item?.trim() === itemInput?.trim() ||
          item?.upc?.trim() === itemInput?.trim()
      );

      if (!foundItem) {
        throw new Error(`Item ${itemInput} is not in tote`);
      }

      //get line code for oreilly
      if (tote?.memo === "O'Reilly-CA") {
        const itemResponse = await axios.get(
          `items/get/${
            foundItem.interchange && foundItem.interchange.interchange
              ? foundItem.interchange.interchange
              : foundItem.item
          }?subsidiary=${currentSubsidiary}`
        );
        if (
          !itemResponse.data?.orly_linecode ||
          !itemResponse.data?.orly_linecode === "0"
        ) {
          throw new Error(
            `No O'Reilly Line Code is present for item: ${foundItem?.item}`
          );
        }

        finalBrand = itemResponse.data?.orly_linecode;
      }
      openAlert({
        type: "success",
        message: `Printing Item ${foundItem?.item}...`,
        duration: 2000,
      });

      //write print function
      await printToteSingleItemLabel({
        item: foundItem,
        brand: finalBrand,
        currentPrinter,
      });

      //logging
      const printLogsBody = {
        user: username,
        item: foundItem?.item,
        brand: "PLAIN",
        refnumber: tote?.toteid,
        qty: 1,
        type: "tote-item",
        location: currentLocation,
        printer: currentPrinter?.printername,
        subsidiary: currentSubsidiary,
      };
      await axios.post("print-logs", printLogsBody);
    } catch (error) {
      setError(error?.response?.data?.msg || error.message);
    }
  };
  //prints summary label
  const handlePrintSummaryLabel = async () => {
    try {
      openAlert({
        type: "success",
        message: `Printing Summary Label...`,
        duration: 2000,
      });

      await printToteSummaryLabel({
        toteid: toteInput,
        items: tote?.items,
        totalitemsqty: tote?.totalitemsqty,
        currentPrinter,
      });

      //logging
      const printLogsBody = {
        user: username,
        item: "SUMMARY",
        brand: "PLAIN",
        refnumber: tote?.toteid,
        qty: 1,
        type: "tote-summary",
        location: currentLocation,
        printer: currentPrinter?.printername,
        subsidiary: currentSubsidiary,
      };
      await axios.post("print-logs", printLogsBody);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Couldnt print summary label: ${
          error?.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    }
  };
  //resets tote and sets it back to search tote
  const handleResetTote = () => {
    setError("");
    setItemInput("");
    setToteInput("");
    setTote(null);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Print Tote Items
      </Typography>

      {Boolean(tote) ? (
        // tote set, scan items
        <Box component="form" onSubmit={handleToteItemSubmit}>
          <FlexWrapper alignItems="center" justifyContent="flex-start">
            <Button
              size="small"
              variant="outlined"
              sx={{ mb: 0.5 }}
              endIcon={<Replay />}
              onClick={handleResetTote}
            >
              Reset Tote
            </Button>
          </FlexWrapper>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography fontWeight="bold" fontSize="1rem">
                {tote?.toteid} ({tote?.memo})
              </Typography>
              <CheckCircle color="success" fontSize="small" />
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton color="primary" onClick={handlePrintSummaryLabel}>
                <Print />
              </IconButton>
              <Divider flexItem orientation="vertical" />
              <Typography variant="body2">
                {currentPrinter?.printername}
              </Typography>
            </Box>
          </Box>
          <TextField
            autoFocus
            required
            fullWidth
            label="Enter Tote Item"
            value={itemInput}
            onChange={(event) => {
              const itemSplit = event.target.value?.toUpperCase()?.split("*");
              const item = itemSplit[0];
              setItemInput(item);
            }}
            error={Boolean(error)}
            helperText={error || ""}
            InputProps={{
              endAdornment: (
                <IconButton type="submit">
                  <Search />
                </IconButton>
              ),
            }}
          />
        </Box>
      ) : (
        // tote not set, scan tote
        <Box component="form" onSubmit={handleToteLabelSubmit} sx={{ mt: 2 }}>
          <TextField
            autoFocus
            required
            fullWidth
            label="Enter Tote Label"
            value={toteInput}
            onChange={(event) =>
              setToteInput(event.target?.value?.toUpperCase()?.trim())
            }
            error={Boolean(error)}
            helperText={error || ""}
            InputProps={{
              endAdornment: (
                <IconButton type="submit">
                  <Search />
                </IconButton>
              ),
            }}
          />
        </Box>
      )}
    </Container>
  );
};
export default PrintToteItemLabels;
