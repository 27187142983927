import { RemoveRedEye } from "@mui/icons-material";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { Button } from "@mui/material";

import ViewAWSImages from "../../warehouse/pack-process/update-pack-order/ViewAWSImages";

const ImageUploadViewAwsImages = ({ images }) => {
  const { openAlert } = useGeneralAlert();
  return (
    <ViewAWSImages
      currentImages={images}
      openAlert={openAlert}
      openComponent={
        <Button variant="text" size="small" endIcon={<RemoveRedEye />}>
          {images?.length}
        </Button>
      }
      type="click"
    />
  );
};
export default ImageUploadViewAwsImages;
