export const FULLBINSCONSOLIDATIONREPORTCOLUMNS = [
  {
    field: "binbrand",
    headerName: "Bin Brand",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "itemname",
    headerName: "Item",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numberofbins",
    headerName: "# of Bins",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "bins",
    headerName: "Bins",
    type: "string",
    flex: 6,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sumavailable",
    headerName: "Sum",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "itempalletqty",
    headerName: "Item Pallet Qty",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];

export const PARTIALBINSREPORTCOLUMNS = [
  {
    field: "binbrand",
    headerName: "Bin Brand",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "itemname",
    headerName: "Item",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numberofbins",
    headerName: "# of Bins",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "bins",
    headerName: "Bins",
    type: "string",
    flex: 6,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sumavailable",
    headerName: "Sum",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "halfpalletqty",
    headerName: "Half Pallet Qty",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];

export const BINSMORETAHNONEITEMCOLUMNS = [
  {
    field: "binbrand",
    headerName: "Bin Brand",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "binsize",
    headerName: "Bin Size",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "binnumber",
    headerName: "Bin Number",
    type: "string",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "numberofitems",
    headerName: "# of Items",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "items",
    headerName: "Items",
    type: "string",
    flex: 5,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sumavailable",
    headerName: "Sum",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];
