import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Container,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Loading from "../../Loading";
import FlexWrapper from "../../FlexWrapper";
import { Search } from "@mui/icons-material";

const PickOrderRemainingView = () => {
  const [uniquePath, setUniquePath] = useState(null);
  const [totalQty, setTotalQty] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const matches = useMediaQuery("(max-width:600px)");
  const { path, filteredbins } = useSelector((state) => state.pickOrder);

  useEffect(() => {
    if (path.length) {
      const storage = {};
      const uniqueLineIds = Array.from(
        path
          .reduce((map, obj) => {
            map.set(obj.lineid, obj);
            return map;
          }, new Map())
          .values()
      );

      for (let i = 0; i < uniqueLineIds.length; i++) {
        const item = uniqueLineIds[i];

        if (!storage[item.lineitemname]) {
          storage[item.lineitemname] = {
            lineid: item.lineid,
            name: item.lineitemname,
            picked: item.lineitemqtypicked || 0,
            committed: item.lineitemqtycommitted,
            upc: item.lineitemupc,
          };
        } else {
          if (item.lineid !== storage[item.lineitemname].lineid) {
            storage[item.lineitemname].committed += item.lineitemqtycommitted;
          }

          storage[item.lineitemname].picked += item.lineitemqtypicked || 0;
        }
      }
      let totalQty = 0;
      for (const key of Object.keys(storage)) {
        const item = storage[key];
        totalQty += item.committed - item.picked;
      }
      setTotalQty(totalQty);
      setUniquePath(storage);
    }
  }, []);

  if (!path?.length)
    return (
      <FlexWrapper height="50vh" justifyContent="center" alignItems="center">
        <Typography variant="h5" textAlign="center">
          No Items Remaining
        </Typography>
      </FlexWrapper>
    );

  if (!uniquePath) return <Loading message="Loading Path..." />;

  // Filter items based on search query
  const filteredItems = Object.keys(uniquePath).filter(
    (key) =>
      uniquePath[key].name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      uniquePath[key].upc.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxWidth="lg">
      <FlexWrapper justifyContent={matches ? "center" : "space-between"}>
        <TextField
          fullWidth
          size="small"
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => {
            //needed for new labels
            const itemSplit = e.target.value?.toUpperCase()?.split("*");
            const item = itemSplit[0];
            setSearchQuery(item);
          }}
          sx={{ maxWidth: matches ? "none" : 300, mb: matches ? 0.5 : 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FlexWrapper flexDirection="column">
          {Boolean(filteredbins) ? (
            <Typography
              textAlign="center"
              variant={matches ? "caption" : "subtitle2"}
            >
              Filtered Bins: <strong>{filteredbins}</strong>
            </Typography>
          ) : null}
          <FlexWrapper gap={1}>
            <Typography variant={matches ? "caption" : "subtitle2"}>
              Items Remaining: <strong>{Object.keys(uniquePath).length}</strong>
            </Typography>
            <Typography variant={matches ? "caption" : "subtitle2"}>
              Qty Remaining: <strong>{totalQty}</strong>
            </Typography>
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxHeight: matches ? "68vh" : "77vh",
          overflowY: "scroll",
          pb: 2,
        }}
      >
        <Stepper alternativeLabel={false} orientation="vertical">
          {filteredItems
            ? filteredItems.map((key) => (
                <Step key={key}>
                  <StepLabel
                    optional={
                      <Typography variant="body1">
                        QTY:{" "}
                        {uniquePath[key].committed - uniquePath[key].picked}
                      </Typography>
                    }
                  >
                    <Typography variant="h5">{uniquePath[key].name}</Typography>
                  </StepLabel>
                </Step>
              ))
            : null}
        </Stepper>
      </Box>
    </Container>
  );
};
export default PickOrderRemainingView;
