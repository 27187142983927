import { useEffect, useState } from "react";
import { Cancel, Visibility } from "@mui/icons-material";
import {
  Box,
  Dialog,
  IconButton,
  ImageList,
  ImageListItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import GeneralModalV3 from "../../../general/GeneralModalV3";
import Loading from "../../../Loading";

const ViewAWSImages = ({
  currentImages,
  openAlert,
  openComponent,
  type = "initial",
}) => {
  const [imageURLS, setImageURLS] = useState([]);
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (currentImages?.length && type === "initial") {
      getImageURLS();
    }
  }, [currentImages]);

  const getImageURLS = async () => {
    setLoading("Loading Images...");
    try {
      const imageResponse = await axios.post("files/multi/signedURL", {
        options: { expiresIn: 3600 },
        fileKeys: currentImages,
      });

      setImageURLS(imageResponse.data);
    } catch (error) {
      setImageURLS([]);
      openAlert({
        type: "error",
        message: `Could Not Get Images:${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  if (Boolean(loading) && type === "initial")
    return <Loading message={loading} />;

  if (!currentImages?.length) {
    return (
      <Typography variant="h4" textAlign="center">
        No Image Data To Display
      </Typography>
    );
  }

  return (
    <GeneralModalV3
      width={matches ? 400 : 800}
      openComponent={
        openComponent ? (
          openComponent
        ) : (
          <Tooltip title="View Existing Images" placement="top" arrow>
            <IconButton>
              <Visibility color="primary" />
            </IconButton>
          </Tooltip>
        )
      }
      onOpen={type === "click" ? getImageURLS : null}
    >
      {Boolean(loading) && type === "click" ? (
        <Loading message={loading} />
      ) : (
        <Box>
          <Box py={1} display="flex" justifyContent="center">
            <ImageList
              sx={{ width: matches ? 350 : 750, height: matches ? 320 : 550 }}
              cols={matches ? 1 : 2}
              rowHeight={350}
              gap={"10px"}
            >
              {imageURLS.map((image) => (
                <ImageListItem
                  key={image}
                  onClick={() => handleClickOpen(image)}
                >
                  <img
                    srcSet={`${image}`}
                    src={`${image}?fit=crop&auto=format`}
                    alt="AWS"
                    loading="lazy"
                    style={{ objectFit: "contain" }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
          <Dialog fullScreen open={open} onClose={handleClose} fullWidth>
            <Box height="100%" position="relative">
              <img
                src={`${selectedImage}`}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
              <IconButton
                onClick={handleClose}
                style={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                }}
              >
                <Cancel />
              </IconButton>
            </Box>
          </Dialog>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default ViewAWSImages;
