import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { REPLENISHMENTANALYSISCOLUMNS } from "../../utils/columns/procurement/procurementColumns";
import { createReplenishmentAnalysisData } from "../../redux/features/procurement/procurementSlice";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import FlexWrapper from "../../components/FlexWrapper";

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const procurement = useSelector((state) => state.procurement);

  const ReplenishmentColumns = [
    {
      id: "line",
      label: "Line",
    },

    {
      id: "Item Class",
      label: "Item Class",
    },
    {
      id: "Item Number",
      label: "Item Number",
    },
    {
      id: "Item Interchange",
      label: "Item Interchange",
    },
    {
      id: "brand",
      label: "Brand",
    },
    { id: "Qty", label: "Quantity" },
    {
      id: "From Location",
      label: "From Location",
    },
    {
      id: "To Location",
      label: "To Location",
    },
    { id: "action", label: "Action" },
    {
      id: "daysOfSupply",
      label: "Days Of Supply",
    },
  ];

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer>
      <FlexWrapper justifyContent="space-between" width="100%">
        <Box>
          <FlexWrapper alignItems="center" gap={1}>
            <GridToolbarFilterButton />
            <Tooltip title="Download to CSV">
              <IconButton id="csv-button" onClick={handleClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "csv-button",
              }}
            >
              <CSVLink
                data={procurement.replenishmentAnalysis}
                headers={ReplenishmentColumns.map((item) => {
                  return { label: item.label, key: item.id };
                })}
                filename={`replenishment_analysis_all_${new Date().toDateString()}`}
                style={{ textDecoration: "none" }}
              >
                <MenuItem sx={{ textDecoration: "none" }} onClick={handleClose}>
                  <Typography
                    sx={{ color: "black" }}
                    variant="body1"
                    onClick={handleClose}
                  >
                    Download All Records
                  </Typography>
                </MenuItem>
              </CSVLink>
              <CSVLink
                data={procurement.replenishmentAnalysis.filter(
                  (data) => data.action === "Transfer"
                )}
                headers={ReplenishmentColumns.map((item) => {
                  return { label: item.label, key: item.id };
                })}
                filename={`replenishment_analysis_transfer_${new Date().toDateString()}`}
                style={{ textDecoration: "none" }}
              >
                <MenuItem onClick={handleClose}>
                  <Typography
                    sx={{ color: "black" }}
                    variant="body1"
                    onClick={handleClose}
                  >
                    Download Transfer Records
                  </Typography>
                </MenuItem>
              </CSVLink>
              <CSVLink
                data={procurement.replenishmentAnalysis.filter(
                  (data) => data.action === "Buy"
                )}
                headers={ReplenishmentColumns.map((item) => {
                  return { label: item.label, key: item.id };
                })}
                filename={`replenishment_analysis_buy_${new Date().toDateString()}`}
                style={{ textDecoration: "none" }}
              >
                <MenuItem onClick={handleClose}>
                  <Typography
                    sx={{ color: "black" }}
                    variant="body1"
                    onClick={handleClose}
                  >
                    Download Buy Records
                  </Typography>
                </MenuItem>
              </CSVLink>
            </Menu>
          </FlexWrapper>
        </Box>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const ReplenishmentAnalysis = () => {
  const dispatch = useDispatch();
  const procurement = useSelector((state) => state.procurement);

  useEffect(() => {
    if (
      procurement?.replenishmentAnalysis?.length === 0 &&
      !procurement?.replenishmentAnalysisLastUpdateDate
    ) {
      dispatch(createReplenishmentAnalysisData());
    }
  }, []);

  if (procurement.replenishmentLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          height: "80vh",
        }}
      >
        <CircularProgress variant="indeterminate" value={10} />
        <Typography variant="body1" color="primary">
          Loading Replenishment Data...May Take A Few Minutes
        </Typography>
      </Box>
    );
  }

  if (procurement.replenishmentError) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          height: "80vh",
        }}
      >
        <Typography variant="body1" color="primary">
          Cannot Get Data: {procurement.replenishmentError}
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Typography variant="h3" component="h1" textAlign="center" gutterBottom>
        Replenishment Analysis
      </Typography>
      {procurement.replenishmentAnalysis.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            height: "80vh",
          }}
        >
          <Typography color="primary">No Data To Display</Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">
              Data Last Updated On:{" "}
              {new Date(
                procurement.replenishmentAnalysisLastUpdateDate
              ).toLocaleString()}
            </Typography>
          </Box>

          <DataGrid
            sx={{
              maxHeight: "75vh",
              ".Mui-selected": {
                backgroundColor: "rgb(139 198 255) !important",
              },
            }}
            columns={REPLENISHMENTANALYSISCOLUMNS}
            rows={procurement.filteredReplenishmentAnalysis}
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              toolbar: {
                printOptions: {
                  disableToolbarButton: true,
                },
                showQuickFilter: true,
              },
            }}
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              },
            }}
            pageSizeOptions={[100, 250, 500, 1000]}
            density="compact"
            getRowId={(row) => row.line}
          />
        </>
      )}
    </Container>
  );
};

export default ReplenishmentAnalysis;
