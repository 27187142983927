import { useState } from "react";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { resizeFile } from "../../../utils/files/resizeFile";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ExitToApp } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import GeneralFileUpload from "../../../components/GeneralFileUpload";
import axios from "../../../axios/axios.config";
import Loading from "../../../components/Loading";
import GeneralModalV3 from "../../../components/general/GeneralModalV3";

const ImageUploadPage = () => {
  const [files, setFiles] = useState([]);
  const [input, setInput] = useState({ type: "", reference: "", details: "" });
  const [loading, setLoading] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const navigate = useNavigate();
  const { openAlert } = useGeneralAlert();
  const {
    currentLocation,
    username,
    currentSubsidiary,
    currentDepartment,
    rolelevel,
  } = useSelector((state) => state.user);

  const handleSaveFiles = async (newFiles) => {
    try {
      const resizedFiles = [];
      for (const f of newFiles) {
        const resizedFile = await resizeFile({
          file: new File(
            [f],
            `${input.type}-${input.reference}-${username}-${f.name}`,
            {
              type: f.type,
              lastModified: f.lastModified,
            }
          ),
          maxWidth: 2500,
          maxHeight: 2500,
          compressFormat: "JPEG",
          quality: 80,
          outputType: "file",
        });
        resizedFiles.push(resizedFile);
      }

      setFiles([...files, ...resizedFiles]);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Error: ${error.message}`,
        duration: 3000,
      });
    }
  };

  const handleRemoveFile = (file) => {
    const newFiles = files.filter((fileObj) => fileObj.name !== file.name);

    setFiles(newFiles);
  };

  const handleChanges = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("Uploading...");
    try {
      //Save File to S3
      const imageKeys = [];
      const formData = new FormData();
      const folder = `images/uploads/${currentLocation}/`;
      formData.append("folder", folder);

      for (const file of files) {
        //will store keys in mongo to generate URLs once saved
        imageKeys.push(folder + file.name);
        formData.append("files", file);
      }

      formData.append(
        "imageMongoBody",
        JSON.stringify({
          type: input.type,
          reference: input.reference,
          details: input.details,
          images: imageKeys,
          location: currentLocation,
          subsidiary: currentSubsidiary,
          department: currentDepartment,
          createdby: username,
        })
      );

      //upload images to s3
      await axios.post("multi-actions/images/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setFiles([]);
      setInput({ type: "", reference: "", details: "" });
      setIsSuccessModalOpen(true);
      setLoading("");
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Upload Form: ${error.message}`,
        duration: 8000,
      });
      setLoading("");
    }
  };

  const handleExit = () => {
    navigate(`/portal/${currentDepartment?.toLowerCase()}`);
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  if (isSuccessModalOpen) {
    return (
      <GeneralModalV3
        onClose={() => setIsSuccessModalOpen(false)}
        disableEscapeKeyDown
        openVal={isSuccessModalOpen}
      >
        {(handleClose) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              p: 3,
              textAlign: "center",
            }}
          >
            <Typography variant="h4" fontWeight="bold" color="success.main">
              Success!
            </Typography>
            <Typography variant="body1">
              Form has been successfully submitted
            </Typography>
            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={() => handleClose()}
            >
              OK
            </Button>
          </Box>
        )}
      </GeneralModalV3>
    );
  }

  return (
    <Container maxWidth="md" component="form" onSubmit={handleSubmit}>
      <IconButton color="error" onClick={handleExit}>
        <ExitToApp />
      </IconButton>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Upload Form
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl required fullWidth>
            <InputLabel htmlFor="type">Type</InputLabel>
            <Select
              name="type"
              value={input.type}
              label="Type"
              id="type"
              fullWidth
              onChange={handleChanges}
            >
              <MenuItem value="Receiving">Receiving</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Reference #"
            variant="outlined"
            fullWidth
            name="reference"
            value={input.reference}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Details"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="details"
            value={input.details}
            onChange={handleChanges}
          />
        </Grid>
        <Grid item xs={12}>
          <GeneralFileUpload
            multiple
            handleSaveFiles={handleSaveFiles}
            handleRemoveFile={handleRemoveFile}
            files={files}
            acceptedFiles={{
              "image/png": [".png", ".jpeg", ".jpg"],
            }}
            capture={true}
            disabled={!input.type || !input.reference}
          />
        </Grid>
        {rolelevel === 0 && (
          <Grid item xs={12}>
            <label for="picture">
              Take a picture using back facing camera:
            </label>

            <input
              type="file"
              id="picture"
              name="picture"
              accept="image/*"
              capture="environment"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={
              files.length === 0 ||
              !input.type ||
              !input.reference ||
              !input.details
            }
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default ImageUploadPage;
