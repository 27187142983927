import { useState } from "react";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import FlexWrapper from "../../../../components/FlexWrapper";
import Loading from "../../../../components/Loading";

const ToteInspectionResultsPage = () => {
  const data = useLoaderData();
  const navigate = useNavigate();

  const [toteItems, setToteItems] = useState(data?.expandedItems);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState("");

  const { username, currentDepartment } = useSelector((state) => state.user);
  const { toteid } = useParams();
  const { openAlert } = useGeneralAlert();

  const remaining = toteItems?.filter((item) => item?.status === "")?.length;

  const handleStatusChange = (uniqueId, newStatus) => {
    setToteItems((prevItems) =>
      prevItems.map((item) =>
        item.uniqueId === uniqueId ? { ...item, status: newStatus } : item
      )
    );
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleSubmitInspection = async () => {
    const newStatus = remaining === 0 ? "inspected" : "being-inspected";
    const inspectedBy = username;

    setLoading(`${remaining === 0 ? "Completing" : "Saving"} Inspection...`);
    try {
      //organize items by mongo id and status
      const storage = {};
      for (const item of toteItems) {
        const { mongoid, status } = item;

        if (!storage[mongoid]) {
          storage[mongoid] = { good: 0, scrap: 0 };
        }

        if (status === "Good") {
          storage[mongoid].good += 1;
        }

        if (status === "Scrap") {
          storage[mongoid].scrap += 1;
        }
      }

      //create new array from original items
      const newItems = data?.items?.map((item) => {
        return {
          ...item,
          goodConditionCount: storage[item?.mongoid].good,
          scrapConditionCount: storage[item?.mongoid].scrap,
        };
      });

      //update db
      await axios.patch(
        `totes/update/tote-inspection/complete-inspection/${toteid}`,
        { newStatus, newItems, inspectedBy }
      );

      navigate(`/portal/${currentDepartment?.toLowerCase()}/tote-inspection`, {
        replace: true,
      });

      openAlert({
        type: "success",
        duration: 2000,
        message: `Successully ${
          remaining === 0 ? "Completed" : "Saved"
        } Inspection`,
      });
    } catch (error) {
      openAlert({
        type: "error",
        duration: 8000,
        message: `Could not ${
          remaining === 0 ? "complete" : "save"
        } inspection: ${error?.response?.data?.msg || error.message}`,
      });
      setLoading("");
    }
  };

  const filteredItems = toteItems.filter((item) =>
    item.item.toLowerCase().includes(searchQuery)
  );

  const displayedItems =
    tabIndex === 0
      ? filteredItems // All items
      : filteredItems.filter((item) => !item.status); // Remaining items

  if (data === null) {
    return (
      <Box px={1}>
        <Typography py={2} textAlign="center" variant="h4">
          Could Not Get Tote Data
        </Typography>
        <FlexWrapper justifyContent="center">
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                `/portal/${currentDepartment?.toLowerCase()}/tote-inspection`,
                {
                  replace: true,
                }
              )
            }
          >
            Back To Search
          </Button>
        </FlexWrapper>
      </Box>
    );
  }

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="xl">
      <FlexWrapper justifyContent="space-between">
        <Typography variant="h4" gutterBottom textAlign="center">
          {data?.toteid}
        </Typography>

        <Button
          disabled={
            remaining === data?.expandedItems?.length || remaining === 0
          }
          variant="outlined"
          color="success"
          endIcon={<Save />}
          onClick={handleSubmitInspection}
        >
          Save and Exit
        </Button>
      </FlexWrapper>

      {/* Tabs and Search */}
      <Grid container spacing={2} alignItems="center" sx={{ mb: 1 }}>
        <Grid item xs={12} md={6}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={`All Items (${data?.expandedItems?.length})`} />
            <Tab label={`Remaining Items (${remaining})`} />
          </Tabs>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Search items..."
            onChange={handleSearchChange}
            value={searchQuery}
          />
        </Grid>
      </Grid>

      {/* Scrollable Stack */}
      <Box
        sx={{
          maxHeight: { xs: "55vh", md: "70vh" },
          overflowY: "auto",
          border: "1px solid #ccc",
          borderRadius: 2,
          p: 1,
        }}
      >
        <Stack spacing={1}>
          {displayedItems?.length ? (
            displayedItems.map((item) => (
              <Card key={item.uniqueId}>
                <CardContent>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">{item.item}</Typography>
                    <RadioGroup
                      row
                      value={item?.status}
                      onChange={(event) =>
                        handleStatusChange(item.uniqueId, event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="Good"
                        control={<Radio />}
                        label="Good"
                      />
                      <FormControlLabel
                        value="Scrap"
                        control={<Radio />}
                        label="Scrap"
                      />
                    </RadioGroup>
                  </FlexWrapper>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography py={1} variant="h5" textAlign="center">
              No Items To Display
            </Typography>
          )}
        </Stack>
      </Box>

      {/* Submit Button */}
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        disabled={remaining > 0}
        onClick={handleSubmitInspection}
      >
        Submit Inspection
      </Button>
    </Container>
  );
};
export default ToteInspectionResultsPage;
