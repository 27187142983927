import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Container,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  AssignmentLate,
  AssignmentReturn,
  AssignmentReturned,
  Search,
} from "@mui/icons-material";

import FlexWrapper from "../../FlexWrapper";

const PickOrderDiscrepancies = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const matches = useMediaQuery("(max-width:600px)");
  const { discrepancies } = useSelector((state) => state.pickOrder);

  const stepIcons = {
    ITEM: <AssignmentReturned color="error" />,
    BIN: <AssignmentLate color="error" />,
    "EMPTY-ISSUE": <AssignmentLate color="error" />,
    "EMPTY-OK": <AssignmentReturn />,
    SKIP: <AssignmentReturn />,
  };

  if (!discrepancies?.length)
    return (
      <FlexWrapper height="50vh" justifyContent="center" alignItems="center">
        <Typography variant="h5" textAlign="center">
          No Discrepancies To Display
        </Typography>
      </FlexWrapper>
    );

  // Filter items based on search query
  const filteredItems = discrepancies.filter(
    (item) =>
      item.bin.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.item.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.lineitemupc.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxWidth="lg">
      <FlexWrapper justifyContent={matches ? "center" : "flex-start"}>
        <TextField
          fullWidth
          size="small"
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => {
            //needed for new labels
            const itemSplit = e.target.value?.toUpperCase()?.split("*");
            const item = itemSplit[0];
            setSearchQuery(item);
          }}
          sx={{ maxWidth: matches ? "none" : 300, mb: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </FlexWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxHeight: matches ? "71vh" : "70vh",
          overflowY: "scroll",
        }}
      >
        <Stepper alternativeLabel={false} orientation="vertical" sx={{ py: 2 }}>
          {filteredItems.map((item) => (
            <Step key={item._id}>
              <StepLabel
                error={
                  item.shorttype.toUpperCase() !== "SKIP" &&
                  item.shorttype.toUpperCase() !== "EMPTY-OK"
                }
                optional={
                  <Box
                    sx={{
                      color:
                        item.shorttype.toUpperCase() !== "SKIP" &&
                        item.shorttype.toUpperCase() !== "EMPTY-OK"
                          ? "#d32f2f"
                          : "black",
                    }}
                  >
                    <Typography variant="body1">
                      {item.item} -{" "}
                      {item.shorttype.toUpperCase() === "SKIP" ||
                      item.shorttype.toUpperCase() === "EMPTY-OK"
                        ? "Skipped"
                        : "Short"}{" "}
                      Qty: {item.shortqty}
                    </Typography>
                    <Typography variant="body2">
                      {item.loggedby} -{" "}
                      {new Date(item.createdAt).toLocaleString()}
                    </Typography>
                  </Box>
                }
                icon={stepIcons[item.shorttype.toUpperCase()]}
              >
                <Typography
                  variant="h5"
                  color={
                    item.shorttype.toUpperCase() !== "SKIP" &&
                    item.shorttype.toUpperCase() !== "EMPTY-OK"
                      ? "#d32f2f"
                      : "black"
                  }
                >
                  {item.bin} ({item.shorttype.toUpperCase()})
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Container>
  );
};
export default PickOrderDiscrepancies;
