import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Cancel, Print, Search } from "@mui/icons-material";
import {
  printItemCrossRef,
  printItemPlainLabel,
  printSupersededLabel,
} from "../utils/printer-functions/printLabelFunctions";
import {
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Box,
  FormHelperText,
  Divider,
  Container,
  Button,
  Paper,
  Tabs,
  Tab,
  useMediaQuery,
  Tooltip,
  Link,
  IconButton,
} from "@mui/material";

import axios from "../axios/axios.config";
import ImageIcon from "@mui/icons-material/Image";
import FlexWrapper from "../components/FlexWrapper";
import ViewVehicleApplicationsModal from "../components/reverse-logistics/item-disposition/ViewVehicleApplicationsModal";
import GeneralFullScreenModalV3 from "../components/general/modals/GeneralFullScreenModalV3";

//Component that will hadle displaying disposition data
const ItemDisplay = ({
  data,
  title,
  fullLocationData,
  imageURL = null,
  item = null,
  type = "item",
}) => {
  const [tabValue, setTabValue] = useState(0);

  const { rolelevel, firstname, lastname } = useSelector((state) => state.user);
  const { currentPrinter } = useSelector((state) => state.printers);

  const matches = useMediaQuery("(max-width:600px)");

  const handleTabChanges = (e, newTabValue) => {
    setTabValue(newTabValue);
  };

  if (type !== "item" && rolelevel <= 3 && fullLocationData?.length > 1) {
    return (
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChanges}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Scrollable Location Info Tabs"
        >
          {fullLocationData.map((location, index) => {
            return (
              <Tab
                key={location._id.item + index}
                label={`${location._id.brand}-${location._id.location}`}
              />
            );
          })}
        </Tabs>

        <Paper elevation={2} sx={{ mt: 1 }}>
          <Typography variant="h6" px={2} py={matches ? 0.4 : 2}>
            {title}
          </Typography>
          <Divider />
          <Typography variant="body1" px={2} py={matches ? 0.4 : 2}>
            Brand-Location:{" "}
            <strong>{`${fullLocationData[tabValue]._id.brand}-${fullLocationData[tabValue]._id.location}`}</strong>
          </Typography>
          <Divider />
          <Typography variant="body1" px={2} py={matches ? 0.4 : 2}>
            Item: <strong>{`${fullLocationData[tabValue]._id.item}`}</strong>
          </Typography>
          <Divider />
          <Typography variant="body1" px={2} py={matches ? 0.4 : 2}>
            Qty Needed:{" "}
            <strong>{`${fullLocationData[tabValue].qtyneeded}`}</strong>
          </Typography>
          <Divider />
          <Typography variant="body1" px={2} py={matches ? 0.4 : 2}>
            Estimated Months Left:{" "}
            <strong>{`${fullLocationData[tabValue].estmonthsleft}`}</strong>
          </Typography>
          <Divider />
        </Paper>
      </Box>
    );
  }

  return (
    <Paper elevation={2}>
      <FlexWrapper justifyContent="space-between" alignItems="center">
        <FlexWrapper alignItems="center" gap={1}>
          <Typography variant="h6" pl={2} py={matches ? 0.4 : 2}>
            {title}
          </Typography>
          {data.vehicle_applications ? (
            <ViewVehicleApplicationsModal data={data.vehicle_applications} />
          ) : null}
        </FlexWrapper>

        <FlexWrapper gap={2}>
          {imageURL && (
            <GeneralFullScreenModalV3
              keepMounted={true}
              openComponent={
                <Tooltip title="View Image" placement="top" arrow>
                  <ImageIcon
                    color="primary"
                    fontSize="large"
                    sx={{ mr: 2, cursor: "pointer" }}
                  />
                </Tooltip>
              }
            >
              {(handleClose) => (
                <Box height="99%" width="100%">
                  <IconButton
                    onClick={handleClose}
                    sx={{ position: "absolute", top: 10, left: 10, zIndex: 10 }}
                  >
                    <Cancel />
                  </IconButton>
                  <iframe
                    src={imageURL}
                    id="uxiframe-1356-iframeEl"
                    data-ref="iframeEl"
                    name="uxiframe-1356-frame"
                    width="100%"
                    height="100%"
                    frameborder="0"
                  ></iframe>
                </Box>
              )}
            </GeneralFullScreenModalV3>
          )}

          {item && (
            <Tooltip title="Print AIT Label" arrow placement="top">
              <Print
                color="primary"
                fontSize="large"
                sx={{ mr: 2, cursor: "pointer" }}
                onClick={() =>
                  printItemPlainLabel({
                    item,
                    printer: currentPrinter.printnodeid,
                    brandText: fullLocationData?.length
                      ? `${fullLocationData[0]?._id?.brand}-${fullLocationData[0]?._id?.location}`
                      : null,
                    userinitials: `${firstname[0]}${lastname[0]}`,
                  })
                }
              />
            </Tooltip>
          )}
        </FlexWrapper>
      </FlexWrapper>
      <Divider />
      {Object.keys(data).map((key, index) => {
        if (key === "vehicle_applications") return;
        return (
          <div key={key}>
            {index === Object.keys(data).length - 1 ? (
              <Typography variant="body1" px={2} py={matches ? 0.4 : 2}>
                {key}: <strong>{data[key]}</strong>
              </Typography>
            ) : (
              <>
                <Typography variant="body1" px={2} py={matches ? 0.4 : 2}>
                  {key}: <strong>{data[key]}</strong>
                </Typography>
                <Divider />
              </>
            )}
          </div>
        );
      })}
    </Paper>
  );
};

const ItemDisposition = () => {
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const [item, setItem] = useState(null);
  const [wholeItem, setWholeItem] = useState(null);
  const dispositionRef = useRef(null);
  const [understock, setUnderstock] = useState(null);
  const [fullLocationData, setFullLocationData] = useState([]);
  const [displayName, setDisplayName] = useState(null);
  const [crossRefDisplayName, setCrossRefDisplayName] = useState(null);
  const [crossRefItem, setCrossRefItem] = useState(null);
  const [ssPart, setSSPart] = useState(null);
  const [qchold, setQCHold] = useState(false);
  const navigate = useNavigate();
  const { currentSubsidiary, firstname, lastname } = useSelector(
    (state) => state.user
  );
  const { currentPrinter } = useSelector((state) => state.printers);
  const matches = useMediaQuery("(max-width:600px)");

  const searchItem = async (e) => {
    e.preventDefault();
    setItem(null);
    setUnderstock(null);
    setDisplayName(null);
    setCrossRefDisplayName(null);
    setCrossRefItem(null);
    setError("");
    try {
      if (input == "") {
        setError("Item Cannot Be Empty");
        return;
      }

      let invalid = false;
      let item = null;

      const res = await axios.get(`items/${input}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          subsidiary: currentSubsidiary,
        },
      });

      item = res.data.length ? res.data[0] : null;

      if (!item) {
        //check item crossref
        const { data } = await axios.get(
          `item-cross-refs/item/${input}/${currentSubsidiary}`
        );

        if (!data.length) {
          setError("Item Not Found");
          return;
        }
        //array is length 1 or more
        if (data.length === 1) {
          setCrossRefItem(data[0]);
        } else {
          const firstItem = data[0].item;
          for (let i = 1; i < data.length; i++) {
            if (data[i].item !== firstItem) {
              setError(
                `More than one item found for: ${input}\nThe following part numbers returned a match: ${data
                  .map((item) => item.item)
                  .join(",")}`
              );
              return;
            }
          }
          // If all items are the same, return the first object in the array
          setCrossRefItem(data[0]);
        }

        //if cross ref found, check against item collection again and pass in item
        const secondItemResponse = await axios.get(`items/${data[0].item}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            subsidiary: currentSubsidiary,
          },
        });

        if (
          !secondItemResponse.data.length ||
          (secondItemResponse.data.length &&
            currentSubsidiary !== secondItemResponse.data[0].subsidiary)
        ) {
          setError("Item Not Found.");
          return;
        }

        item = secondItemResponse.data[0];
        setCrossRefDisplayName(
          `Cross Reference found for: ${data[0].bpn} (${data[0].upc})`
        );
      }
      //used to print item
      setWholeItem(item);

      setItem({
        Item: item.item,
        "Base Number": item.basenumber,
        UPC: item.upc,
        vehicle_applications: item.vehicle_applications || "",
      });

      if (item.qchold) {
        setQCHold(true);
        setDisplayName("Item is on QC HOLD, Return to Vendor.");
        return;
      } else {
        if (item.ss || item.discont) {
          if (item.ss) {
            setSSPart(item.sspart);
          }
          setDisplayName(item["item+displayname"]);
          invalid = true;
          return;
        }
      }

      await getLocation(item.basenumber, invalid);
      dispositionRef.current.focus();
      dispositionRef.current.inputMode = "none";
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/");
        return;
      }
      setError(`Could Not Get Item: ${error.message}`);
    } finally {
      setInput("");
    }
  };

  const getLocation = async (item, invalid) => {
    try {
      const res = await axios.get(`understock/${item}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (invalid || res.data.length === 0) {
        setUnderstock("empty");
        setFullLocationData([]);
        return;
      }

      setFullLocationData(res.data);

      setUnderstock({
        "Brand-Location": `${res.data[0]._id.brand}-${res.data[0]._id.location}`,
        Item: res.data[0]._id.item,
        "Qty Needed": res.data[0].qtyneeded.toLocaleString("en-US"),
        "Estimated Months Left": res.data[0].estmonthsleft.toFixed(2),
      });
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/");
        return;
      }
    }
  };

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          py: { xs: 1, md: 3 },
        }}
      >
        <Box
          sx={{ pb: { xs: 2, md: 5 } }}
          component="form"
          onSubmit={searchItem}
        >
          <FormControl
            sx={{ width: { xs: "100%", md: "50%" } }}
            error={error.length > 0}
            size={matches ? "small" : "medium"}
          >
            <InputLabel htmlFor="component-outlined">Check Item</InputLabel>
            <OutlinedInput
              autoFocus
              autoComplete="off"
              inputProps={{ autoComplete: "off" }}
              onClick={() => (dispositionRef.current.inputMode = "text")}
              inputRef={dispositionRef}
              onFocus={() => setError("")}
              label="Check Item"
              id="component-outlined"
              value={input}
              required
              onChange={(e) => {
                if (understock || displayName || qchold) {
                  setUnderstock(null);
                  setDisplayName(null);
                  setSSPart(null);
                  setItem(null);
                  setQCHold(false);
                  setCrossRefDisplayName(null);
                  setCrossRefItem(null);
                }

                const itemSplit = e.target.value?.toUpperCase()?.split("*");
                const item = itemSplit[0];
                setInput(item);
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{
                    cursor: "pointer",
                    height: "100%",
                  }}
                  onClick={searchItem}
                >
                  <Search />
                </InputAdornment>
              }
            />
            <FormHelperText sx={{ whiteSpace: "pre-line" }}>
              {error}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box>
          {crossRefDisplayName && !ssPart && !displayName && !qchold ? (
            <>
              <Typography fontWeight="bold" variant="body1" gutterBottom>
                {crossRefDisplayName}
              </Typography>
              <Button
                onClick={() => {
                  printItemCrossRef(
                    crossRefItem,
                    wholeItem,
                    currentPrinter.printnodeid,
                    fullLocationData.length
                      ? `${fullLocationData[0]?._id?.brand}-${fullLocationData[0]?._id?.location}`
                      : null,
                    `${firstname[0]}${lastname[0]}`
                  );
                }}
                variant="contained"
                sx={{ mb: 3 }}
              >
                Print AIT Label
              </Button>
            </>
          ) : null}

          {item ? (
            <ItemDisplay
              title="Item Details"
              data={item}
              imageURL={`https://WWW.SHOWMETHEPARTSDB3.COM/BIN/images/Trakmotive/360_Files/${item["Base Number"]}.html`}
              item={wholeItem}
              fullLocationData={fullLocationData}
            />
          ) : (
            <Typography textAlign="center" variant="h5">
              No Item
            </Typography>
          )}

          <Divider sx={{ mt: 2, border: "1px solid lightgrey" }} />

          {understock === "empty" && !displayName ? (
            <Typography fontWeight="bold" pt={2}>
              Item Not Needed, Return to Vendor
            </Typography>
          ) : null}

          <Box>
            {displayName ? (
              <Typography fontWeight="bold" variant="body1" py={2}>
                {displayName}
              </Typography>
            ) : null}

            {ssPart && displayName && !qchold && !crossRefDisplayName ? (
              <Button
                onClick={() =>
                  printSupersededLabel(
                    item,
                    ssPart,
                    currentPrinter.printnodeid,
                    currentSubsidiary
                  )
                }
                variant="contained"
                sx={{ mt: 1 }}
              >
                Print Superseded Label
              </Button>
            ) : null}
          </Box>

          {understock && understock !== "empty" && !displayName ? (
            <ItemDisplay
              title="Location Details"
              data={understock}
              fullLocationData={fullLocationData}
              type="location"
            />
          ) : null}
        </Box>
      </Container>
    </>
  );
};

export default ItemDisposition;
