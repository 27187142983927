import { useEffect, useState } from "react";
import { Assignment, FactCheck, MoreVert } from "@mui/icons-material";
import { useLoaderData, useOutletContext } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";

import FlexWrapper from "../../../../components/FlexWrapper";
import ReceiveTransactionActions from "../../../../components/warehouse/receive-transaction/ReceiveTransactionActions";
import ReceiveTransactionScanView from "../../../../components/warehouse/receive-transaction/ReceiveTransactionScanView";
import Loading from "../../../../components/Loading";
import ReceiveTransactionInfoModal from "../../../../components/warehouse/receive-transaction/ReceiveTransactionInfoModal";
import ReceiveTransactionItemsScannedView from "../../../../components/warehouse/receive-transaction/ReceiveTransactionItemsScannedView";
import axios from "../../../../axios/axios.config";
import ReceiveTransactionSearchModal from "../../../../components/warehouse/receive-transaction/ReceiveTransactionSearchModal";
import ReceiveTransactionInitialScreen from "../../../../components/warehouse/receive-transaction/ReceiveTransactionInitialScreen";
import ReceiveTransactionUpdateReferenceModal from "../../../../components/warehouse/receive-transaction/ReceiveTransactionUpdateReferenceModal";

const ReceiveTransactionResultPage = () => {
  const {
    loading,
    navigate,
    currentDepartment,
    username,
    rolelevel,
    dispatchAlert,
    setLoading,
    matches,
  } = useOutletContext();

  const data = useLoaderData();

  const [bottomNavigationValue, setBottomNavigationValue] =
    useState("remaining");
  const [transactionData, setTransactionData] = useState(data);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initial, setInitial] = useState(true);
  const [isReferenceModalOpen, setIsReferenceModalOpen] = useState(false);

  useEffect(() => {
    //checks to see if name matches the created by name and also checks role level
    if (data && username && rolelevel !== undefined) {
      if (username !== data?.createdby && parseInt(rolelevel) !== 0) {
        navigate(
          `/portal/${currentDepartment?.toLowerCase()}/receive-transaction`,
          { replace: true }
        );
      }
    }
  }, [username, rolelevel, data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (option) => {
    setAnchorEl(null);
    setLoading("Updating Condition...");
    try {
      const response = await axios.patch(
        `receive-transaction/${transactionData._id}/update/condition`,
        {
          newCondition: option,
        }
      );

      setTransactionData(response.data);
      dispatchAlert({
        type: "success",
        message: `Successfully updated condition`,
        duration: 3000,
      });
    } catch (error) {
      dispatchAlert({
        type: "error",
        message: `Could not update condition: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 10000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleBottomNavigationChange = (event, newValue) => {
    setBottomNavigationValue(newValue);
  };

  if (!transactionData)
    return (
      <FlexWrapper
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        height="30vh"
      >
        <Typography variant="h3" py={2} textAlign="center">
          Could not get data for this order
        </Typography>
        <Button
          variant="contained"
          onClick={() =>
            navigate(
              `/portal/${currentDepartment.toLowerCase()}/receive-transaction`
            )
          }
        >
          Back To Search
        </Button>
      </FlexWrapper>
    );

  if (initial)
    return (
      <ReceiveTransactionInitialScreen
        order={transactionData.tranid}
        trantype={transactionData.trantype}
        condition={transactionData.condition}
        brandpackaging={transactionData.brandpackaging}
        customer={transactionData.entity}
        location={transactionData.location}
        date={transactionData.date}
        memo={transactionData.memo}
        warehousenotes={transactionData.warehousenotes}
        reference={transactionData.tranref}
        setInitial={setInitial}
      />
    );

  if (Boolean(loading)) return <Loading message={loading} />;

  return (
    <Container maxWidth="xl" sx={{ px: 1, pt: 1 }}>
      {/* Reference Modal */}
      <ReceiveTransactionUpdateReferenceModal
        open={isReferenceModalOpen}
        setOpen={setIsReferenceModalOpen}
        reference={transactionData.tranref}
        _id={transactionData._id}
        setTransactionData={setTransactionData}
      />

      <FlexWrapper flexDirection="column" alignItems="flex-end">
        <FlexWrapper justifyContent="center" alignItems="center" width="100%">
          <Typography
            textAlign="center"
            sx={{ typography: { xs: "h5", md: "h4" }, pr: 1 }}
          >
            {transactionData.tranid} - {transactionData.brandpackaging}
          </Typography>
          {/* Info Modal */}
          <ReceiveTransactionInfoModal transactionData={transactionData} />
          {bottomNavigationValue === "remaining" && (
            <ReceiveTransactionSearchModal items={transactionData.items} />
          )}
        </FlexWrapper>
        {/* Actions Button */}
        <FlexWrapper
          gap={3}
          alignItems="baseline"
          justifyContent="space-between"
          width={matches ? "100%" : "auto"}
        >
          {transactionData.condition && (
            <>
              <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
                <Badge
                  variant="dot"
                  color={
                    transactionData.condition === "good" ? "success" : "error"
                  }
                >
                  <Typography>
                    Condition:{" "}
                    <Typography component="span" fontWeight="bold">
                      {transactionData?.condition?.toUpperCase()}
                    </Typography>
                  </Typography>
                </Badge>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleMenuItemClick("good")}>
                  GOOD
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("scrap")}>
                  SCRAP
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("rust")}>
                  RUST
                </MenuItem>
              </Menu>
            </>
          )}
          <ReceiveTransactionActions
            transactionData={transactionData}
            setIsReferenceModalOpen={setIsReferenceModalOpen}
          />
        </FlexWrapper>
      </FlexWrapper>
      {/* Remaining Section */}
      {
        <Box
          sx={{
            display: bottomNavigationValue === "remaining" ? "block" : "none",
          }}
        >
          <ReceiveTransactionScanView
            transactionData={transactionData}
            setTransactionData={setTransactionData}
            bottomNavigationValue={bottomNavigationValue}
          />
        </Box>
      }
      {/* Items Scanned Section */}
      {bottomNavigationValue === "scanned" && (
        <ReceiveTransactionItemsScannedView
          transactionData={transactionData}
          setTransactionData={setTransactionData}
        />
      )}
      {/* Bottom Navigation */}
      <Paper
        elevation={3}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <BottomNavigation
          sx={{ width: "100%" }}
          showLabels
          value={bottomNavigationValue}
          onChange={handleBottomNavigationChange}
        >
          <BottomNavigationAction
            label="Remaining"
            value="remaining"
            icon={<Assignment />}
          />
          <BottomNavigationAction
            label="Scanned"
            value="scanned"
            icon={<FactCheck />}
          />
        </BottomNavigation>
      </Paper>
    </Container>
  );
};
export default ReceiveTransactionResultPage;
