import axios from "../../../axios/axios.config";

//gets total quantities being picked for bin
export const getTotalItemQtyCurrentlyBeingPicked = async ({
  subsidiary,
  locationinternalid,
  lineiteminternalid,
  bininternalid,
}) => {
  try {
    const { data } = await axios.get(
      `pick-order/get/total-item-bin-qty?subsidiary=${subsidiary}&locationinternalid=${locationinternalid}&lineiteminternalid=${lineiteminternalid}&bininternalid=${bininternalid}`
    );

    if (data?.totalQty) {
      return data.totalQty;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};
//gets total quantity being moved for bin
export const getTotalItemQtyCurrentlyBeingMoved = async ({
  subsidiary,
  locationinternalid,
  lineiteminternalid,
  bininternalid,
}) => {
  try {
    const { data } = await axios.get(
      `pick-order/get/total-inventory-move-qty?subsidiary=${subsidiary}&locationinternalid=${locationinternalid}&lineiteminternalid=${lineiteminternalid}&bininternalid=${bininternalid}`
    );

    if (data?.totalQtyBeingMoved) {
      return data.totalQtyBeingMoved;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};
//checks current total bin quantity with latest quantity check
export const checkBinQuantites = async ({
  binonhandavailable,
  subsidiary,
  locationinternalid,
  bininternalid,
  lineiteminternalid,
  totalItemQtyCurrentlyBeingPicked,
  totalItemQtyCurrentlyBeingMoved,
}) => {
  try {
    let currentBinQty = parseInt(binonhandavailable); // already has
    console.log(currentBinQty, "current Bin Quantity");
    //  consolidate into on function
    //First make a call to find the total amount of item qty that is currently being picked in bin
    let newTotalItemQtyCurrentlyBeingPicked =
      await getTotalItemQtyCurrentlyBeingPicked({
        subsidiary,
        locationinternalid,
        bininternalid,
        lineiteminternalid,
      });
    //make a call to get the qty of item currently being transferred from bin
    let newTotalItemQtyCurrentlyBeingMoved =
      await getTotalItemQtyCurrentlyBeingMoved({
        subsidiary,
        locationinternalid,
        bininternalid,
        lineiteminternalid,
      });

    console.log(totalItemQtyCurrentlyBeingPicked, "current picked");
    console.log(totalItemQtyCurrentlyBeingMoved, "current moved");

    const currentQtyInUse =
      totalItemQtyCurrentlyBeingPicked + totalItemQtyCurrentlyBeingMoved;

    const newQtyInUse =
      newTotalItemQtyCurrentlyBeingMoved + newTotalItemQtyCurrentlyBeingPicked;
    console.log(
      newTotalItemQtyCurrentlyBeingMoved,
      newTotalItemQtyCurrentlyBeingPicked,
      "new being moved, being picked"
    );
    console.log(currentQtyInUse, newQtyInUse, "current and new");

    const difference = newQtyInUse - currentQtyInUse;
    console.log(difference, "difference");
    const currentQty = currentBinQty;
    //negative differences are okay since that means items being moved or picked are no longer in progress and not in bin
    const isEqual =
      difference < 0
        ? true
        : parseInt(currentQtyInUse) === parseInt(newQtyInUse);
    const newQty = difference < 0 ? currentQty : currentQty - difference;

    return {
      isEqual,
      currentQty,
      newQty,
    };
  } catch (error) {
    return null;
  }
};
