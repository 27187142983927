import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import Loading from "../../../Loading";
import axios from "../../../../axios/axios.config";
import FlexWrapper from "../../../FlexWrapper";
import GeneralModalV3 from "../../../general/GeneralModalV3";
import CycleCountsSendEmailModal from "../../../multi-page/general/CycleCountsSendEmailModal";

const EnterCycleCountsStartCountPageComponent = ({
  binPath,
  setJobData,
  parentId,
  setLoading,
  setCurrentBinPath,
  headerData,
  setHeaderData,
  uniqueItemsMap,
}) => {
  const [item, setItem] = useState("");
  const [count, setCount] = useState("");
  const [currentCount, setCurrentCount] = useState(null); //opens edit dialog

  const { user, navigation, openAlert, matches } = useOutletContext();
  const itemInputRef = useRef(null);
  const qtyInputRef = useRef(null);

  useEffect(() => {
    if (itemInputRef?.current) {
      handleItemRefFocus();
    }
  }, []);

  //values used in page
  const currentItem = binPath.remaining?.length
    ? binPath.remaining.find(
        (obj) =>
          obj?.itemData?.itemname?.toUpperCase() === item ||
          obj?.itemData?.itemupc?.split("-")[0] === item
      )
    : null; //gets the next lineitemjob to count
  const currentBin = binPath?.binNumber ? binPath?.binNumber : null; //gets the next lineitemjob to count
  const originalJobId = binPath?.all?.length
    ? binPath?.all[0].originaljob
    : null; //original job id used when we create a new job
  const isQtyVerified =
    count !== "" && count >= 0 && !String(count).includes(".");
  const isExistingItem = binPath.all?.length
    ? binPath.all.find(
        (obj) =>
          obj?.itemData?.itemname?.toUpperCase() === item ||
          obj?.itemData?.itemupc?.split("-")[0] === item
      )
    : null;
  const uncountedItems = binPath.all?.length
    ? binPath.all.filter((obj) => obj?.status !== "counted")
    : null;

  //submits count for item
  const handleSubmitCount = async (qty, newItem = null, finishing = false) => {
    //finishing is used when there are still items leftover but user clicked finish bin
    if (!finishing) {
      setLoading("Submitting Count...");
    }

    try {
      let updateResponse = null;
      let binInternalId = null;
      let locationInternalId = null;
      let binQty = 0;
      let totalPickInProgress = 0;
      let totalInventoryMoveInProgress = 0;
      let itemInteralId = newItem
        ? parseInt(newItem?.internalid)
        : parseInt(currentItem.itemData.iteminternalid);

      if (parseInt(qty) > 999999) {
        throw new Error("QTY can not exceed 999999");
      }

      if (currentItem?.status === "counted") {
        throw new Error("Item has already been counted");
      }

      //if new order, update status and fields
      if (headerData.status === "new") {
        const newOrderResponse = await axios.patch(
          `cycle-counts-jobs/update/${parentId}/start-count`,
          { userid: user._id }
        );

        setHeaderData({
          ...headerData,
          status: newOrderResponse.data.status,
          startedon: newOrderResponse.data.startedon,
          startedby: newOrderResponse.data.startedby,
          lockedby: newOrderResponse.data.lockedby,
        });
      }

      const binResponse = await axios.post(
        "netsuite/post/pick-order/validate/bin",
        {
          binNumber: currentBin,
          itemInteralId,
        }
      );

      if (binResponse.data?.length) {
        const { bininternalid, binonhandavailable, locationinternalid } =
          binResponse.data[0];

        binInternalId = parseInt(bininternalid);
        locationInternalId = parseInt(locationinternalid);
        binQty = parseInt(binonhandavailable);
      } else {
        //make extra API call if binResponse is empty to get values
        const binDataResponse = await axios.get(
          `netsuite/cycle-counts/get/bin-data?bin=${currentBin}`
        );
        //if data gets returned from NetSuite then save bin id and location id
        if (binDataResponse.data.length) {
          binInternalId = parseInt(binDataResponse.data[0].id);
          locationInternalId = parseInt(binDataResponse.data[0].location);
        }
      }

      if (binInternalId && locationInternalId) {
        //make call to get qty currently being picked
        const pickInProgressResponse = await axios.get(
          `pick-order/get/total-item-bin-qty?subsidiary=${user.currentSubsidiary}&locationinternalid=${locationInternalId}&lineiteminternalid=${itemInteralId}&bininternalid=${binInternalId}`
        );

        totalPickInProgress = parseInt(pickInProgressResponse.data?.totalQty);

        //make call to get qty currently being picked
        const inventoryMoveInProgressResponse = await axios.get(
          `pick-order/get/total-inventory-move-qty?subsidiary=${user.currentSubsidiary}&locationinternalid=${locationInternalId}&lineiteminternalid=${itemInteralId}&bininternalid=${binInternalId}`
        );

        totalInventoryMoveInProgress = parseInt(
          inventoryMoveInProgressResponse.data?.totalQtyBeingMoved
        );
      }

      const totalVirtualQty =
        binQty - totalPickInProgress - totalInventoryMoveInProgress;

      const countData = {
        nsqty: binQty, //we get bin qty from NS
        physicalcount: parseInt(qty),
        countedby: user._id,
        pickinprogress: totalPickInProgress,
        movesinprogress: totalInventoryMoveInProgress,
        virtualqty: totalVirtualQty,
        variance: parseInt(qty) - totalVirtualQty,
      };

      if (currentItem || (newItem && finishing)) {
        updateResponse = await axios.patch(
          `cycle-counts-line-items/update/${
            newItem ? newItem._id : currentItem._id
          }/add-count`,
          { parentId, countData }
        );
      } else {
        //new item being added
        const itemData = {
          iteminternalid: newItem.internalid,
          itemname: newItem.item,
          itemdescription: newItem.description ? newItem.description : "",
          itemupc: newItem.upc,
          itembinnumber: currentBin,
          itemlocationinternalid: locationInternalId,
          isnewitem: true,
        };

        const currentlinkedjob = parentId;
        const originaljob = originalJobId;

        const newLineItemJobData = {
          itemData,
          counts: [countData],
          currentlinkedjob,
          originaljob,
          status: "counted",
        };
        const isNewUniqueItem = uniqueItemsMap.has(newItem.item);

        //code to add item into jobs and get new jobs
        updateResponse = await axios.post(
          `cycle-counts-line-items/create-one`,
          {
            currentJobId: currentlinkedjob,
            lineItemJobData: newLineItemJobData,
            newItem: isNewUniqueItem,
          }
        );
        //updates unique items if its a new item in CC
        if (isNewUniqueItem) {
          setHeaderData({
            ...headerData,
            uniqueitems: parseInt(headerData.uniqueitems) + 1,
          });
        }
      }

      if (!updateResponse) {
        throw new Error("Could Not Get Updated Data. Please Refresh.");
      }

      if (!finishing) {
        setJobData(updateResponse.data.jobs);

        openAlert({
          type: "success",
          message: "Successfully Added Count",
          duration: 1000,
        });
      }
    } catch (error) {
      setLoading("");
      openAlert({
        type: "error",
        message: error.response?.data?.msg || error.message,
        duration: 10000,
      });
    }
  };

  const handleAddNewItem = async () => {
    setLoading("Adding New Item...");
    try {
      //make call to mongo to get item info
      const itemResponse = await axios.get(
        `items/get/${item}?subsidiary=${user.currentSubsidiary}`
      );

      handleSubmitCount(count, itemResponse.data);
    } catch (error) {
      setLoading("");
      openAlert({
        type: "error",
        message: error.response?.data?.msg || error.message,
        duration: 10000,
      });
    }
  };

  const handleFinalizeBin = async () => {
    setLoading("Finishing Count...");
    try {
      //if items are leftover without counts then submit counts of 0
      if (uncountedItems?.length) {
        for (const uncountedItem of uncountedItems) {
          await handleSubmitCount(
            0,
            {
              internalid: uncountedItem.itemData.iteminternalid,
              item: uncountedItem.itemData.itemname,
              class: uncountedItem.itemData.itemname,
              upc: uncountedItem.itemData.itemupc,
              _id: uncountedItem._id,
            },
            true
          );
        }
      }

      const lineIds = binPath.all.map((obj) => obj._id);
      const response = await axios.patch(
        `cycle-counts-line-items/update/${parentId}/finalize-count`,
        { lineIds }
      );

      setJobData(response.data.jobs);
      setCurrentBinPath(null);
      openAlert({
        type: "success",
        message: `Successfully completed count for ${currentBin}`,
        duration: 5000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: error.response?.data?.msg || error.message,
        duration: 10000,
      });
    }
  };

  const handleItemRefFocus = () => {
    if (itemInputRef.current) {
      itemInputRef.current.readOnly = true;
      itemInputRef.current.focus();
      setTimeout(() => {
        itemInputRef.current.focus();
        itemInputRef.current.readOnly = false;
      });
    }
  };
  //handles submitting item input to focus on qty input
  const handleItemInputSubmit = (event) => {
    event.preventDefault();
    if (item) {
      itemInputRef.current.blur();
      qtyInputRef.current.focus();
    }
  };

  const handleClickOpen = (obj) => {
    itemInputRef.current.blur();
    setCurrentCount(obj);
  };

  const handleClose = () => {
    setCurrentCount(null);
    handleItemRefFocus();
  };

  const handleQtyEditSubmit = async (event, obj) => {
    event.preventDefault();
    setLoading("Updating Count...");
    try {
      const formData = new FormData(event.currentTarget);
      const formJson = Object.fromEntries(formData.entries());
      const qty = formJson.qty;
      let binQty = 0;
      let totalPickInProgress = 0;
      let totalInventoryMoveInProgress = 0;
      let itemInteralId = parseInt(obj.itemData.iteminternalid);

      //get virtual data
      //get ns qty
      const binResponse = await axios.post(
        "netsuite/post/pick-order/validate/bin",
        {
          binNumber: currentBin,
          itemInteralId,
        }
      );

      if (binResponse.data?.length) {
        const { bininternalid, binonhandavailable, locationinternalid } =
          binResponse.data[0];

        const binInternalId = parseInt(bininternalid);
        const locationInternalId = parseInt(locationinternalid);
        binQty = parseInt(binonhandavailable);

        //make call to get qty currently being picked
        const pickInProgressResponse = await axios.get(
          `pick-order/get/total-item-bin-qty?subsidiary=${user.currentSubsidiary}&locationinternalid=${locationInternalId}&lineiteminternalid=${itemInteralId}&bininternalid=${binInternalId}`
        );

        totalPickInProgress = parseInt(pickInProgressResponse.data?.totalQty);

        //make call to get qty currently being picked
        const inventoryMoveInProgressResponse = await axios.get(
          `pick-order/get/total-inventory-move-qty?subsidiary=${user.currentSubsidiary}&locationinternalid=${locationInternalId}&lineiteminternalid=${itemInteralId}&bininternalid=${binInternalId}`
        );

        totalInventoryMoveInProgress = parseInt(
          inventoryMoveInProgressResponse.data?.totalQtyBeingMoved
        );
      }

      const totalVirtualQty =
        binQty - totalPickInProgress - totalInventoryMoveInProgress;
      const variance = parseInt(qty) - parseInt(totalVirtualQty);

      //update physical count for counts
      const newCounts = obj.counts.map((count, index) => {
        if (index === obj.counts.length - 1) {
          return {
            ...count,
            physicalcount: parseInt(qty),
            variance,
            nsqty: binQty,
            pickinprogress: totalPickInProgress,
            movesinprogress: totalInventoryMoveInProgress,
            virtualqty: totalVirtualQty,
          };
        }
        return count;
      });

      // route to update counts
      const response = await axios.patch(
        `cycle-counts-line-items/update/${parentId}/change-line-counts`,
        { newCounts, lineItemId: obj._id }
      );

      setJobData(response.data.jobs);
      handleClose();
      openAlert({
        type: "success",
        message: `Successfully updated count for ${obj.itemData.itemname}`,
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: error.response?.data?.msg || error.message,
        duration: 10000,
      });
    } finally {
      setLoading("");
    }
  };

  //loading screen when navigating or submitting
  if (navigation.state === "loading") {
    return <Loading message="Navigating..." />;
  }

  return (
    <Container maxWidth="md">
      {/* Edit Dialog */}
      <Dialog
        open={Boolean(currentCount)}
        onClose={handleClose}
        hideBackdrop
        PaperProps={{
          component: "form",
          onSubmit: (event) => handleQtyEditSubmit(event, currentCount),
        }}
      >
        <DialogTitle>Edit Quantity</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter New Quantity for{" "}
            <strong>{currentCount?.itemData?.itemname}</strong>.
          </DialogContentText>
          <TextField
            autoFocus={Boolean(currentCount)}
            required
            margin="dense"
            id="qty"
            name="qty"
            label="Enter Qty"
            type="number"
            fullWidth
            variant="standard"
            inputProps={{ min: 0, max: 999999 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </Dialog>

      <FlexWrapper
        flexDirection="column"
        height={matches ? "calc(100vh - 70px)" : "95vh"}
        width="100%"
        flexWrap="nowrap"
      >
        <FlexWrapper
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <IconButton onClick={() => setCurrentBinPath(null)}>
            <KeyboardBackspace />
          </IconButton>
          <FlexWrapper alignItems="center" gap={1}>
            <CycleCountsSendEmailModal
              onClose={handleItemRefFocus}
              bin={currentBin}
              type={"Cycle Counts"}
              jobName={headerData.name}
              items={binPath?.all
                ?.map((itemObj) => itemObj.itemData?.itemname)
                .join(",")}
            />
            <Divider flexItem orientation="vertical" />
            <Typography variant="h6" fontWeight="bold">
              {currentBin}
            </Typography>
          </FlexWrapper>
        </FlexWrapper>

        <FlexWrapper
          flexDirection="column"
          justifyContent="space-between"
          width="100%"
          height="100%"
        >
          <Stack spacing={2} width="100%">
            <form onSubmit={handleItemInputSubmit}>
              <TextField
                required
                size="small"
                inputRef={itemInputRef}
                autoFocus={!item}
                label="Enter Item"
                fullWidth
                value={item}
                onChange={(event) => {
                  const itemSplit = event.target.value
                    ?.toUpperCase()
                    ?.trim()
                    ?.split("*");
                  const item = itemSplit[0];
                  setItem(item);
                }}
                error={!currentItem && isExistingItem}
                helperText={
                  !currentItem && isExistingItem
                    ? "Item has already been counted"
                    : ""
                }
              />
            </form>

            {Boolean(item) && !(!currentItem && isExistingItem) && (
              <TextField
                size="small"
                label="Enter Count Qty"
                type="number"
                inputMode="numeric"
                inputRef={qtyInputRef}
                value={count}
                onChange={(event) => setCount(event.target.value)}
                inputProps={{ min: 0 }}
                error={count < 0 || String(count).includes(".")}
                helperText={
                  count < 0 || String(count).includes(".")
                    ? "Quantity must be an integer greater than or equal to 0"
                    : ""
                }
              />
            )}

            {/* Complete count when item and qty is entered */}
            {currentItem && isQtyVerified && (
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleSubmitCount(count)}
              >
                Add Count
              </Button>
            )}

            {/* Complete count when item and qty is entered */}
            {Boolean(item) && isQtyVerified && !isExistingItem && (
              <GeneralModalV3
                openComponent={
                  <Button fullWidth variant="contained">
                    Add Item
                  </Button>
                }
              >
                {(handleClose) => (
                  <Box textAlign="center">
                    <Typography
                      color={headerData.creationtype === "item" ? "error" : ""}
                      variant="h5"
                      py={1}
                      fontWeight="bold"
                    >
                      New Item {headerData.creationtype === "item" && "Error"}
                    </Typography>
                    <Divider />

                    <Typography p={2} fontSize="18px">
                      {headerData.creationtype === "item"
                        ? "Cannot add new item when Cycle Count is created by item"
                        : "Item is currently not in bin, would you like to add it?"}
                    </Typography>
                    <Divider />

                    <FlexWrapper p={2} gap={3} justifyContent="center">
                      {headerData.creationtype !== "item" ? (
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            handleClose();
                            handleAddNewItem();
                          }}
                        >
                          Yes
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          variant="contained"
                          color="error"
                          onClick={handleClose}
                        >
                          OK
                        </Button>
                      )}
                    </FlexWrapper>
                  </Box>
                )}
              </GeneralModalV3>
            )}
          </Stack>

          {binPath?.all?.length ? (
            <Box width="100%">
              <TableContainer
                component={Paper}
                sx={{ maxHeight: matches ? "40vh" : "50vh", mb: 1 }}
              >
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          textAlign: "center",
                        }}
                      >
                        Items
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          textAlign: "center",
                        }}
                      >
                        Counted
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {binPath?.all?.map((obj) => (
                      <TableRow key={obj._id}>
                        <TableCell
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            textAlign: "center",
                          }}
                        >
                          {obj.itemData?.itemname}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            textAlign: "center",
                          }}
                        >
                          {obj.status === "counted" && obj.counts?.length ? (
                            <Button
                              size="small"
                              variant="text"
                              onClick={() => handleClickOpen(obj)}
                            >
                              <Typography>
                                {
                                  obj.counts[obj.counts?.length - 1]
                                    .physicalcount
                                }
                              </Typography>
                            </Button>
                          ) : // <Typography>
                          //   {obj.counts[obj.counts?.length - 1].physicalcount}
                          // </Typography>
                          null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Complete count when no item is entered */}

              <GeneralModalV3
                openComponent={
                  <Button fullWidth variant="contained" color="success">
                    Finish Bin
                  </Button>
                }
              >
                {(handleClose) => (
                  <Box textAlign="center">
                    <Typography variant="h5" py={1} fontWeight="bold" px={1}>
                      Are you sure you want to finish counting this bin?
                    </Typography>
                    <Divider />

                    <Typography p={2} fontSize="18px">
                      {uncountedItems?.length
                        ? "Some items will save with a zero count. Is this correct?"
                        : "All Items Successfully Counted"}
                    </Typography>
                    <Divider />

                    <Box p={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="success"
                        onClick={handleFinalizeBin}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                )}
              </GeneralModalV3>
            </Box>
          ) : null}
        </FlexWrapper>
      </FlexWrapper>
    </Container>
  );
};

export default EnterCycleCountsStartCountPageComponent;
