import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { Warning } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";

const TOLinkedToSO = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState("Loading Transfer Orders...");

  const { currentNetsuiteLocationIds, currentDepartment, username } =
    useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();
  const navigate = useNavigate();

  useEffect(() => {
    getTransferOrders();
  }, []);

  const getTransferOrders = async () => {
    try {
      const nsResponse = await axios.get(
        `netsuite/order-processing/get/to-linked-so?locations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}`
      );

      setOrderData(nsResponse.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Get Orders: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="lg" sx={{ pb: 1 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        TO Linked To SO
      </Typography>

      {orderData.length ? (
        <>
          <FlexWrapper alignItems="baseline" justifyContent="flex-end">
            <Typography variant="caption" textAlign="center" gutterBottom>
              <strong>Total Orders:</strong> {orderData.length}
            </Typography>
          </FlexWrapper>
          <Stack sx={{ pt: 1 }} spacing={2}>
            {orderData?.map((order) => {
              const isUnderFillRate =
                parseFloat(
                  order["SO Projected Fill Rate %"]?.replace("%", "")
                ) /
                  100 <
                0.93;

              return (
                <Card key={order["TO #"]} style={{ borderRadius: 8 }}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      gutterBottom
                      textAlign="center"
                      fontWeight="bold"
                    >
                      {order["TO #"]}{" "}
                      {order["Brand Packaging"]
                        ? `- ${order["Brand Packaging"]}`
                        : null}
                    </Typography>

                    <Typography variant="body1" pt={1}>
                      <strong>Date: </strong> {order["Date Created"]}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Total Qty: </strong> {order["Total Qty"]}
                    </Typography>
                    <Typography gutterBottom variant="body1">
                      <strong>Status: </strong> {order["Status"]}
                    </Typography>
                    <Divider />
                    <Typography pt={1} variant="body1">
                      <strong>SO Date: </strong> {order["SO Date"]}
                    </Typography>
                    <Typography variant="body1">
                      <strong>SO #: </strong> {order["SO #"]}
                    </Typography>
                    <Typography variant="body1">
                      <strong>SO Shipping Method: </strong>{" "}
                      {order["SO Ship Via"]}
                    </Typography>
                    <Typography variant="body1">
                      <strong>SO Customer: </strong> {order["SO Customer"]}
                    </Typography>
                    <Typography variant="body1">
                      <strong>SO Qty: </strong> {order["SO Qty"]}
                    </Typography>
                    <FlexWrapper gap={1} alignItems="center">
                      <Typography variant="body1">
                        <strong>SO Projected Fill Rate: </strong>{" "}
                        {order["SO Projected Fill Rate %"]}
                      </Typography>
                      {isUnderFillRate ? (
                        <Box pb={1}>
                          <FlexWrapper gap={1} alignItems="center">
                            <Icon>
                              <Warning color="warning" />
                            </Icon>
                            <Typography
                              alignSelf="flex-end"
                              color="error"
                              variant="body2"
                              fontWeight="bold"
                            >
                              SO Under Fill Rate
                            </Typography>
                          </FlexWrapper>
                        </Box>
                      ) : null}
                    </FlexWrapper>
                    <Typography variant="body1">
                      <strong>SO Warehouse Order Status: </strong>{" "}
                      {order["SO Warehouse Order Status"]}
                    </Typography>
                    {/* <Typography variant="body1">
                    <strong>From Location: </strong> {order["From Location"]}
                  </Typography>
                  <Typography variant="body1">
                    <strong>To Location: </strong> {order["To Location"]}
                  </Typography> */}
                  </CardContent>
                  <CardActions>
                    <Button
                      disabled={
                        order["Pick Locked By"] !== "" &&
                        order["Pick Locked By"] !== username
                      }
                      variant="contained"
                      onClick={() => {
                        const redirect = `/portal/${currentDepartment?.toLowerCase()}/pick-to-so?order=${
                          order["TO #"]
                        }`;

                        navigate(
                          `/portal/${currentDepartment?.toLowerCase()}/pick-order?order=${encodeURIComponent(
                            order["TO #"]
                          )}&redirect=${encodeURIComponent(
                            redirect
                          )}&original-page=${encodeURIComponent(
                            `/portal/${currentDepartment?.toLowerCase()}/to-linked-to-so`
                          )}`
                        );
                      }}
                    >
                      Pick Transfer Order
                    </Button>
                  </CardActions>
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    pb={1}
                    textTransform="capitalize"
                  >
                    {order["Pick Locked By"] !== "" &&
                    order["Pick Locked By"] !== username
                      ? `Order already being picked by ${order["Pick Locked By"]}`
                      : ""}
                  </Typography>
                </Card>
              );
            })}
          </Stack>
        </>
      ) : (
        <Typography variant="h6" textAlign="center" py={2}>
          No Orders To Display
        </Typography>
      )}
    </Container>
  );
};
export default TOLinkedToSO;
