export const EMPTYBINSREPORTCOLUMNS = [
  {
    field: "binnumber",
    headerName: "Bin Number",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binbrand",
    headerName: "Bin Brand",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binboxsizetype",
    headerName: "Bin Box Size",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binsize",
    headerName: "Bin Size",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binlocationname",
    headerName: "Bin Location Name",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];

export const ITEMSTOCONSOLIDATEREPORTCOLUMNS = [
  {
    field: "itemclass",
    headerName: "Item Class",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "itemid",
    headerName: "Item",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "numberofbins",
    headerName: "# of Bins",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "bins",
    headerName: "Bins",
    type: "string",
    flex: 3,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "sumavailable",
    headerName: "Sum Available",
    type: "number",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "itempalletqty",
    headerName: "Item Pallet Qty",
    type: "number",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binlocationname",
    headerName: "Bin Location Name",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];

export const AVAILABLEITEMSREPORTCOLUMNS = [
  {
    field: "binnumber",
    headerName: "Bin Number",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "itemid",
    headerName: "Item",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "itemclass",
    headerName: "Item Class",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binavailable",
    headerName: "Bin Qty",
    type: "number",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binbrand",
    headerName: "Bin Brand",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binsize",
    headerName: "Bin Size",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binboxsizetype",
    headerName: "Bin Box Size Type",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "binlocationname",
    headerName: "Bin Location Name",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];

export const REPLENISHFROMOVERSTOCKCOLUMNS = [
  {
    field: "Item",
    headerName: "Item",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Overstock Bin",
    headerName: "Overstock Bin",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Overstock Bin Available",
    headerName: "Overstock Bin Available",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Replenish Qty (Pallets)",
    headerName: "Replenish Qty (Pallets)",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Replenish Qty (Pieces)",
    headerName: "Replenish Qty (Pieces)",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Status",
    headerName: "Status",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Inventory Location Monthly Forecast",
    headerName: "Inventory Location Monthly Forecast",
    type: "number",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Location Available",
    headerName: "Location Available",
    type: "number",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Inventory Location",
    headerName: "Inventory Location",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];
