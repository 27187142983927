import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { Container, IconButton, TextField, Typography } from "@mui/material";

import axios from "../../../axios/axios.config";
import Loading from "../../../components/Loading";

const PickFulfillmentUplaodErrorSearchPage = () => {
  const [orderInput, setOrderInput] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    if (error) {
      setError("");
    }

    event.preventDefault();
    setLoading("Searching Order...");
    try {
      if (!orderInput) throw new Error("Order number is required");

      const response = await axios.get(
        `pick-order/get/all/general/order-number/${orderInput}`
      );
      if (!response.data.length)
        throw new Error(`No data found for ${orderInput}`);

      navigate("results", {
        state: { results: response.data, order: orderInput },
      });
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Pick Order Error
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          autoFocus
          fullWidth
          label="Enter Order Number"
          value={orderInput}
          onChange={(event) =>
            setOrderInput(event.target.value?.trim()?.toUpperCase())
          }
          error={Boolean(error)}
          helperText={error || ""}
          InputProps={{
            endAdornment: (
              <IconButton type="submit">
                <Search />
              </IconButton>
            ),
          }}
        />
      </form>
    </Container>
  );
};
export default PickFulfillmentUplaodErrorSearchPage;
