import ImageUploadViewAwsImages from "../../../../components/multi-page/image-upload/ImageUploadViewAwsImages";

export const VIEWIMAGEUPLOADCOLUMNS = [
  {
    field: "type",
    headerName: "Type",
    type: "text",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "reference",
    headerName: "Reference",
    type: "text",
    flex: 2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "details",
    headerName: "Details",
    type: "text",
    flex: 3,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "createdby",
    headerName: "Created By",
    type: "text",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "date",
    flex: 2,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => new Date(value?.value)?.toLocaleString(),
  },
  {
    field: "images",
    headerName: "Images",
    type: "number",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <ImageUploadViewAwsImages images={params?.row?.images} />
    ),
    valueGetter: (value) => value?.value?.length,
  },
];
