import { useState, useEffect } from "react";
import { useNavigation } from "react-router-dom";
import { toggleNav } from "../../../redux/features/nav/navBarSlice";
import { printPickOrderPalletLabel } from "../../../utils/printer-functions/printPickOrderPalletLabel";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { useDispatch, useSelector } from "react-redux";
import { openFullScreenModal } from "../../../redux/features/modal/modalSlice";
import { checkBinQuantites } from "../../../utils/helper-functions/pick-order/getQuantitiesHelper";
import {
  findNextBin,
  refreshBinData,
} from "../../../redux/features/pick-order/pickOrderSlice";
import {
  FactCheck,
  RemoveCircle,
  Warning,
  Info,
  SyncProblem,
  Assignment,
  Error,
} from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Container,
  Typography,
  Box,
  Tooltip,
  IconButton,
  useMediaQuery,
  Icon,
  Button,
} from "@mui/material";

import PickOrderCurrentView from "../../../components/multi-page/pick-order/PickOrderCurrentView";
import FlexWrapper from "../../../components/FlexWrapper";
import PickOrderRemainingView from "../../../components/multi-page/pick-order/PickOrderRemainingView";
import PickOrderScannedView from "../../../components/multi-page/pick-order/PickOrderScannedView";
import PickOrderDataFullScreenModal from "../../../components/multi-page/pick-order/PickOrderDataFullScreenModal";
import PickOrderActions from "../../../components/multi-page/pick-order/PickOrderActions";
import Loading from "../../../components/Loading";
import PickOrderDiscrepancies from "../../../components/multi-page/pick-order/PickOrderDiscrepancies";
import PickOrderErrorsView from "../../../components/multi-page/pick-order/PickOrderErrorsView";

//Fulfillment function is done in current view and pick actions components. Any updates should be made to both

const PickOrderResultsPage = () => {
  const [value, setValue] = useState("current");
  const [initial, setInitial] = useState(true);
  //used for quantity discrepancy validation
  const [isQuantityErrorModalOpen, setIsQuantityErrorModalOpen] =
    useState(null);

  const pickOrderData = useSelector((state) => state.pickOrder);
  const { currentPrinter } = useSelector((state) => state.printers);
  const { username, currentLocationInternalId } = useSelector(
    (state) => state.user
  );
  const { openAlert } = useGeneralAlert();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    dispatch(openFullScreenModal());
    dispatch(toggleNav(true));

    return () => {
      dispatch(toggleNav(false));
    };
  }, []);

  const handleInitialLoad = async () => {
    await dispatch(refreshBinData()).unwrap();
    await dispatch(findNextBin({ _id: pickOrderData._id })).unwrap();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  //re-checks bin quantities during time of pick
  const checkQuantityFn = async ({
    binonhandavailable,
    subsidiary,
    locationinternalid,
    bininternalid,
    lineiteminternalid,
    totalItemQtyCurrentlyBeingPicked,
    totalItemQtyCurrentlyBeingMoved,
  }) => {
    const result = await checkBinQuantites({
      binonhandavailable,
      subsidiary,
      locationinternalid,
      bininternalid,
      lineiteminternalid,
      totalItemQtyCurrentlyBeingPicked,
      totalItemQtyCurrentlyBeingMoved,
    });

    return { result, updateFn: () => setIsQuantityErrorModalOpen(result) };
  };

  if (pickOrderData?.loading)
    return (
      <Loading
        message={
          navigation.state === "loading"
            ? "Navigating..."
            : pickOrderData.loading
        }
      />
    );

  if (!pickOrderData._id) return null;

  if (!initial && Boolean(isQuantityErrorModalOpen)) {
    return (
      <Container maxWidth="xl" sx={{ pt: 4, textAlign: "center" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Icon fontSize="large" color="error">
              <Error fontSize="inherit" />
            </Icon>
            <Typography variant="h4" color="error" fontWeight="bold">
              Quantity Mismatch Error
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Could not complete pick because bin quantities have changed for
              this item. Please refresh page to get updated quantities.
            </Typography>
            <FlexWrapper gap={1}>
              <Typography
                fontWeight="bold"
                variant="body2"
                color="error"
                sx={{ mb: 1 }}
              >
                Old Available Qty: {isQuantityErrorModalOpen?.currentQty}
              </Typography>
              <Typography
                fontWeight="bold"
                variant="body2"
                color="primary"
                sx={{ mb: 1 }}
              >
                New Available Qty: {isQuantityErrorModalOpen?.newQty}
              </Typography>
            </FlexWrapper>
            <Typography
              fontWeight="bold"
              variant="body2"
              color="textSecondary"
              sx={{ mb: 1 }}
            >
              Note: You may be directed to a different bin after refreshing
            </Typography>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleRefresh}
              sx={{ textTransform: "none" }}
            >
              Refresh Page
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ height: "100vh", pt: 1 }}>
      <PickOrderDataFullScreenModal
        initial={initial}
        setInitial={setInitial}
        handleInitialLoad={handleInitialLoad}
        printFn={async () =>
          await printPickOrderPalletLabel({
            currentPrinter,
            tranid: pickOrderData.tranid,
            username,
            brand: pickOrderData.brandpackaging,
            shippingAddress: pickOrderData.shippingaddress,
            poNumber: pickOrderData.ponumber,
            shipMethod: pickOrderData.shipmethod,
            warehouseNotes: pickOrderData.warehousenotes,
            alertFn: openAlert,
            title: `${pickOrderData.tranid} Pick Order Label`,
            currentLocationInternalId,
            pickQty: pickOrderData.projectedpickqty,
            relatedtransferorders: pickOrderData.relatedtransferorders,
            mustshipwith: pickOrderData.mustshipwith,
            trandate: pickOrderData.trandate,
          })
        }
      />

      <Box pb={1.5}>
        <FlexWrapper
          gap={1}
          flexDirection={matches ? "row" : "column"}
          justifyContent={matches ? "space-between" : "center"}
          alignItems="center"
          flexWrap="nowrap"
        >
          <Typography
            gutterBottom
            sx={{
              typography: { xs: { fontSize: "1.1rem" }, md: "h4" },
              height: 25,
            }}
          >
            {pickOrderData.tranid} - {pickOrderData.brandpackaging}{" "}
            <span>
              <Tooltip title="Order Information" placement="top" arrow>
                <IconButton
                  size="small"
                  onClick={() => dispatch(openFullScreenModal())}
                  disableRipple
                  disableTouchRipple
                  sx={{ p: 0 }}
                >
                  <Info
                    color="primary"
                    fontSize={matches ? "small" : "medium"}
                  />
                </IconButton>
              </Tooltip>
            </span>
          </Typography>

          <PickOrderActions value={value} />
        </FlexWrapper>
      </Box>

      <Box>
        <Box style={{ display: value === "current" ? "block" : "none" }}>
          <PickOrderCurrentView checkQuantityFn={checkQuantityFn} />
        </Box>
        {value === "scanned" && <PickOrderScannedView />}
        {value === "remaining" && <PickOrderRemainingView />}
        {value === "discrepancies" && <PickOrderDiscrepancies />}
        {value === "errors" && <PickOrderErrorsView />}
      </Box>

      <Paper
        elevation={3}
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      >
        <BottomNavigation
          sx={{ width: "100%" }}
          showLabels
          value={value}
          onChange={handleChange}
        >
          <BottomNavigationAction
            label="Current"
            value="current"
            icon={<Assignment />}
          />
          <BottomNavigationAction
            label="Scanned"
            value="scanned"
            icon={<FactCheck />}
          />
          <BottomNavigationAction
            label="Remaining"
            value="remaining"
            icon={<RemoveCircle />}
          />
          <BottomNavigationAction
            label="Discrepancies"
            value="discrepancies"
            icon={<Warning />}
          />
          {!matches && (
            <BottomNavigationAction
              label="Errors"
              value="errors"
              icon={<SyncProblem />}
            />
          )}
        </BottomNavigation>
      </Paper>
    </Container>
  );
};
export default PickOrderResultsPage;
