import { ArrowBack, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import FlexWrapper from "../../../components/FlexWrapper";

const PickFulfillmentUplaodErrorResultsPage = () => {
  const [currentOrder, setCurrentOrder] = useState(null);
  const [pickErrors, setPickErrors] = useState(null);
  const [orderTab, setOrderTab] = useState("picks");

  const location = useLocation();
  const navigate = useNavigate();
  const { currentDepartment } = useSelector((state) => state.user);

  const order = location?.state?.order;
  const results = location?.state?.results;

  if (!results || !order) {
    <Navigate
      to={`/portal/${currentDepartment?.toLowerCase()}/pick-fulfillment-upload-error`}
    />;
  }

  useEffect(() => {
    if (results?.length === 1) {
      const storage = {};
      for (let i = 0; i < results[0]?.pick?.length; i++) {
        const pick = results[0]?.pick[i];
        if (!storage[pick.lineid]) {
          storage[pick.lineid] = {
            orderQty: pick.lineitemqty,
            picked: pick.binqtypicked,
            error: false,
          };
          storage[pick.lineid].error =
            storage[pick.lineid].picked > storage[pick.lineid].orderQty
              ? true
              : false;
        } else {
          storage[pick.lineid].orderQty = Math.max(
            storage[pick.lineid].orderQty,
            pick.lineitemqty
          );
          storage[pick.lineid].picked += pick.binqtypicked;
          storage[pick.lineid].error =
            storage[pick.lineid].picked > storage[pick.lineid].orderQty
              ? true
              : false;
        }
      }

      setCurrentOrder({
        ...results[0],
        pick: results[0]?.pick?.map((pick, index) => {
          const isError = storage[pick.lineid].error;
          //add individual pick errors
          if (isError) {
            storage[pick.lineid].errorItem = pick.lineitemname;

            if (storage[pick.lineid].errorPicks?.length) {
              storage[pick.lineid].errorPicks?.push({
                ...pick,
                errorItemIndex: index,
              });
            } else {
              storage[pick.lineid].errorPicks = [
                { ...pick, errorItemIndex: index },
              ];
            }
          }
          //mark pick array as an error
          return {
            ...pick,
            error: isError,
          };
        }),
      });

      setPickErrors(Object.values(storage)?.filter((obj) => obj.error));
    }
  }, []);

  // Choose Order Screen
  if (results?.length > 1 && !currentOrder) {
    return (
      <Box p={2}>
        <Container maxWidth="md">
          <IconButton
            onClick={() =>
              navigate(
                `/portal/${currentDepartment?.toLowerCase()}/pick-fulfillment-upload-error`,
                { replace: true }
              )
            }
          >
            <Tooltip arrow placement="top" title="Go Back">
              <ArrowBack />
            </Tooltip>
          </IconButton>
          <Typography textAlign="center" variant="h4" gutterBottom>
            Select Order
          </Typography>
          <Typography
            textAlign="right"
            variant="caption"
            component="p"
            gutterBottom
          >
            Total: {results?.length}
          </Typography>
          {results.map((order) => (
            <Card key={order._id} variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <FlexWrapper justifyContent="space-between">
                  <Typography variant="caption">
                    <strong>Created:</strong>{" "}
                    {new Date(order.createdAt)?.toLocaleString()}
                  </Typography>
                  <Typography variant="caption">
                    <strong>Last Picked By:</strong> {order?.lastpickedby}
                  </Typography>
                </FlexWrapper>
                {/* Header Section */}
                <Typography
                  textAlign="center"
                  variant="h5"
                  color="primary"
                  gutterBottom
                >
                  {order.tranid} - {order.brandpackaging}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  <strong>Customer:</strong> {order.customer}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  <strong>Status: </strong>
                  {order.status?.toUpperCase()}{" "}
                  {order.status === "fulfilled"
                    ? new Date(order.timefulfilled)?.toLocaleString()
                    : ""}
                </Typography>
                {order.timefulfilled && (
                  <Typography variant="subtitle1" color="textSecondary">
                    <strong>Fulfillment ID:</strong> {order.fulfillmentid}
                  </Typography>
                )}
                <Typography variant="subtitle1" color="textSecondary">
                  <strong>Picks:</strong> {order.pick?.length}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setCurrentOrder(order)}
                >
                  View
                </Button>
              </CardActions>
            </Card>
          ))}
        </Container>
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ padding: 2 }}>
      <FlexWrapper
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: 2 }}
      >
        {Boolean(currentOrder) && results.length > 1 ? (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setCurrentOrder(null)}
            size="small"
          >
            Change Order
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                `/portal/${currentDepartment?.toLowerCase()}/pick-fulfillment-upload-error`
              )
            }
            size="small"
          >
            Reset Search
          </Button>
        )}

        <Tabs
          value={orderTab}
          onChange={(event, newValue) => setOrderTab(newValue)}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label=" ALl Picks" value="picks" />
          <Tab label="Pick Errors" value="pick-errors" />
        </Tabs>
      </FlexWrapper>

      {Boolean(currentOrder) && (
        <Box>
          <Typography
            fontWeight="bold"
            variant="h5"
            textAlign="center"
            sx={{ marginBottom: 1 }}
          >
            {currentOrder?.tranid}
          </Typography>
          <Typography component="p" variant="caption" textAlign="center">
            {currentOrder?._id}
          </Typography>

          <Box
            sx={{
              marginTop: 1,
              maxHeight: "74vh",
              overflowY: "auto",
              padding: 2,
              backgroundColor: "#f9f9f9",
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            {orderTab === "picks" && (
              <>
                <FlexWrapper gap={1} sx={{ mb: 2 }}>
                  <Typography variant="caption">
                    <strong>Picks:</strong> {currentOrder?.pick?.length}
                  </Typography>
                  <Typography
                    color={pickErrors?.length ? "error" : "success"}
                    component="p"
                    variant="caption"
                  >
                    Pick Errors: {pickErrors?.length || 0}
                  </Typography>
                </FlexWrapper>
                {currentOrder?.pick.length > 0 ? (
                  currentOrder?.pick.map((item, index) => (
                    <Card
                      key={`${item.lineitemname}-${index}`}
                      variant="outlined"
                      sx={{
                        mb: 1,
                        borderColor: item.error ? "red" : "green",
                      }}
                    >
                      <CardContent>
                        <FlexWrapper justifyContent="space-between">
                          <Typography variant="body2" color="textSecondary">
                            Picked By: {item.pickedby}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Picked Time:{" "}
                            {new Date(item.pickedtime).toLocaleString()}
                          </Typography>
                        </FlexWrapper>
                        <Typography
                          variant="h6"
                          color="primary"
                          mb={1}
                          textAlign="center"
                        >
                          Item: {item.lineitemname} - Bin: {item.binnumber}
                        </Typography>
                        <Typography variant="body1" textAlign="center" mb={1}>
                          Qty to Pick: {item.qtytopick} - Qty Picked:{" "}
                          {item.binqtypicked}
                        </Typography>
                        <Typography variant="body2" textAlign="center" mb={1}>
                          Pick Index: {index} - Bin Available:{" "}
                          {item.binonhandavailable}
                        </Typography>
                        <Typography variant="body2" textAlign="center">
                          Line ID: {item.lineid}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    align="center"
                  >
                    No pick data available.
                  </Typography>
                )}
              </>
            )}

            {orderTab === "pick-errors" && (
              <>
                <Typography
                  color={pickErrors?.length ? "error" : "success"}
                  component="p"
                  variant="caption"
                  sx={{ mb: 2 }}
                >
                  Pick Errors: {pickErrors?.length || 0}
                </Typography>
                {pickErrors?.length > 0 ? (
                  pickErrors?.map((item, index) => (
                    <Card
                      key={index}
                      variant="outlined"
                      sx={{
                        mb: 1,
                        borderColor: "red",
                      }}
                    >
                      <CardContent>
                        <FlexWrapper justifyContent="space-between">
                          <Typography
                            fontWeight="bold"
                            variant="h6"
                            sx={{ mb: 1 }}
                          >
                            {item.errorItem}
                          </Typography>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            Order Qty: {item.orderQty} - Picked Qty:{" "}
                            {item.picked}
                          </Typography>
                        </FlexWrapper>
                        <Accordion elevation={0} defaultExpanded={index === 0}>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            Picks ({item.errorPicks?.length})
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={2}>
                              {item.errorPicks?.map((errorItem, errorIndex) => (
                                <Card
                                  key={`${errorItem.lineitemname}-${errorIndex}`}
                                  variant="outlined"
                                  sx={{ mb: 1 }}
                                >
                                  <CardContent>
                                    <FlexWrapper justifyContent="space-between">
                                      <Typography variant="body2">
                                        Picked By: {errorItem.pickedby}
                                      </Typography>
                                      <Typography variant="body2">
                                        Picked Time:{" "}
                                        {new Date(
                                          errorItem.pickedtime
                                        ).toLocaleString()}
                                      </Typography>
                                    </FlexWrapper>
                                    <Typography
                                      variant="h6"
                                      color="primary"
                                      textAlign="center"
                                      sx={{ mb: 1 }}
                                    >
                                      Item: {errorItem.lineitemname} - Bin:{" "}
                                      {errorItem.binnumber}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      textAlign="center"
                                      mb={1}
                                    >
                                      Qty to Pick: {errorItem.qtytopick} - Qty
                                      Picked: {errorItem.binqtypicked}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      textAlign="center"
                                      mb={1}
                                    >
                                      Pick Index: {errorItem.errorItemIndex} -
                                      Bin Available:{" "}
                                      {errorItem.binonhandavailable}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      textAlign="center"
                                    >
                                      Line ID: {errorItem.lineid}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              ))}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    align="center"
                  >
                    No errors to display.
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      )}
    </Container>
  );
};
export default PickFulfillmentUplaodErrorResultsPage;
