import { Container } from "@mui/material";

const WarehouseActivityReport = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        height: "85vh",
        padding: 0, // Remove padding to ensure full width/height usage
      }}
    >
      <iframe
        title="Warehouse Activity Report 2022"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/reportEmbed?reportId=7a524de0-c1bd-4162-81b1-502f2f44da5a&autoAuth=true&ctid=9a6c83c0-8fec-4297-9765-21acf469376b"
        allowFullScreen={true}
        frameBorder={0}
      ></iframe>
    </Container>
  );
};
export default WarehouseActivityReport;
