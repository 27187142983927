import { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { RANKINGPROCESSCOLUMNS } from "../../utils/columns/procurement/procurementColumns";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";

import Loading from "../../components/Loading";
import axios from "../../axios/axios.config";

const RankingProcess = () => {
  const [loading, setLoading] = useState(true);
  const [rankingData, setRankingData] = useState([]);
  const { currentSubsidiary } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    getRankingData();
  }, []);

  const getRankingData = async () => {
    try {
      const totalSalesResponse = await axios.post("procurement/aggregation", {
        aggregation: [
          {
            $group: {
              _id: {
                location: "$Location",
                itemClass: "$ItemClass",
              },
              totalSales: {
                $sum: "$currentrolling12",
              },
            },
          },
          {
            $project: {
              _id: 0,
              location: "$_id.location",
              itemClass: "$_id.itemClass",
              totalSales: 1,
            },
          },
          {
            $addFields: {
              runningTotal: 0,
            },
          },
        ],
      });

      const procurementResponse = await axios.post("procurement/find", {
        filter: { subsidiary: currentSubsidiary },
        sort: { currentrolling12: -1 },
        select: {
          "Item Number": 1,
          ItemClass: 1,
          Location: 1,
          currentrolling12: 1,
        },
      });

      const data = createRankingDataArray(
        totalSalesResponse.data,
        procurementResponse.data
      );
      setRankingData(data);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not get ranking data: ${error.message}`,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const createRankingDataArray = (totalSalesData, procurementData) => {
    const totalSalesStorage = {};
    const rankingData = [];
    //create location-class object for fast data lookup
    for (const totalSalesObj of totalSalesData) {
      const { itemClass, location, totalSales, runningTotal } = totalSalesObj;
      if (!totalSalesStorage[`${location}-${itemClass}`]) {
        totalSalesStorage[`${location}-${itemClass}`] = {
          totalSales,
          runningTotal,
        };
      }
    }
    //loop through procurement data and generate new array
    let i = 1;
    for (const procurementObj of procurementData) {
      const {
        "Item Number": itemNumber,
        Location,
        currentrolling12,
        ItemClass,
        _id,
      } = procurementObj;

      const locationClass = Location + "-" + ItemClass;
      const totalSalesByLocationClass =
        totalSalesStorage[locationClass].totalSales;
      const percentageOfSalesByLocationClass =
        totalSalesByLocationClass === 0
          ? 0
          : currentrolling12 / totalSalesByLocationClass;
      const locationClassRunningTotal =
        totalSalesStorage[locationClass].runningTotal +
        percentageOfSalesByLocationClass;
      let rank = "";

      //calculate rank
      if (locationClassRunningTotal > 0 && locationClassRunningTotal <= 0.46) {
        rank = "A";
      } else if (
        locationClassRunningTotal > 0.46 &&
        locationClassRunningTotal <= 0.66
      ) {
        rank = "B";
      } else if (
        locationClassRunningTotal > 0.66 &&
        locationClassRunningTotal <= 0.86
      ) {
        rank = "C";
      } else if (
        locationClassRunningTotal > 0.86 &&
        locationClassRunningTotal <= 0.96
      ) {
        rank = "D";
      } else if (
        locationClassRunningTotal > 0.96 &&
        locationClassRunningTotal < 1
      ) {
        rank = "E";
      } else {
        rank = "Z";
      }
      //add data to ranking data array
      rankingData.push({
        _id,
        itemNumber,
        Location,
        ItemClass,
        currentrolling12,
        currentrolling12,
        locationClass,
        totalSalesByLocationClass,
        percentageOfSalesByLocationClass,
        locationClassRunningTotal,
        rank,
        line: i,
      });

      //update running total on total sales object
      totalSalesStorage[locationClass].runningTotal =
        totalSalesStorage[locationClass].runningTotal +
        percentageOfSalesByLocationClass;

      //update sequence
      i++;
    }

    return rankingData;
  };

  if (loading) return <Loading message="Loading Ranking Data..." />;

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" textAlign="center" gutterBottom>
        Ranking Process
      </Typography>

      <DataGrid
        sx={{
          maxHeight: "75vh",
          ".Mui-selected": {
            backgroundColor: "rgb(139 198 255) !important",
          },
        }}
        columns={RANKINGPROCESSCOLUMNS}
        rows={rankingData}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: { fileName: `Ranking-Process-${Date.now()}` },
          },
        }}
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[100, 250, 500, 1000]}
        density="compact"
        getRowId={(row) => row._id}
      />
    </Container>
  );
};
export default RankingProcess;
