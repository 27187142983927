import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function WarningActionModal({
  open,
  closeFn,
  title,
  text,
  modalAction,
  dividers = true,
  disabled = false,
}) {
  const handleClose = () => {
    closeFn();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll="paper"
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={dividers}>{text}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button disabled={disabled} onClick={modalAction} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
