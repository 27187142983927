import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import { ArrowBack, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";

const StockLocatorProductLookupPage = () => {
  const [product, setProduct] = useState("");
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { getMovesAndPickInProgress, currentNetsuiteLocationIds } =
    useOutletContext();

  const handleProductSubmit = async (event) => {
    event.preventDefault();
    setLoading("Loading Inventory Data...");

    if (error) {
      setError("");
    }

    try {
      const updatedData = [];
      const inventoryResponse = await axios.get(
        `netsuite/stock-locator/get/inventory?type=product&identifier=${product}&nsLocations=${currentNetsuiteLocationIds.join(
          ","
        )}`
      );

      for (const inventoryData of inventoryResponse.data) {
        updatedData.push({
          ...inventoryData,
          available: await getMovesAndPickInProgress(inventoryData),
        });
      }

      setProductData(updatedData);
    } catch (error) {
      setError(error?.response?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  const handleReset = () => {
    setProduct("");
    setProductData([]);
    setError(null);
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="md">
      <FlexWrapper justifyContent="space-between" alignItems="center">
        <IconButton size="small" onClick={() => navigate(-1)} disableRipple>
          <Tooltip title="Back" arrow placement="bottom">
            <ArrowBack fontSize="small" />
          </Tooltip>
        </IconButton>

        {Boolean(productData?.length) && (
          <Typography fontWeight="bold">{productData[0]?.itemname}</Typography>
        )}

        {Boolean(productData.length) && (
          <Button size="small" variant="outlined" onClick={handleReset}>
            Reset Data
          </Button>
        )}
      </FlexWrapper>

      {!productData?.length ? (
        <form onSubmit={handleProductSubmit}>
          <Typography variant="h4" textAlign="center">
            Product Lookup
          </Typography>
          <TextField
            sx={{ mt: 1 }}
            autoFocus
            label="Enter Product"
            fullWidth
            value={product}
            onChange={(event) => {
              const itemSplit = event.target.value
                ?.toUpperCase()
                ?.trim()
                ?.split("*");
              const item = itemSplit[0];
              setProduct(item);
            }}
            InputProps={{
              endAdornment: (
                <IconButton type="submit">
                  <Search />
                </IconButton>
              ),
            }}
            error={Boolean(error)}
            helperText={error}
          />
        </form>
      ) : (
        <>
          <Stack py={1}>
            <FlexWrapper justifyContent="space-between">
              <Typography variant="body2">
                <strong>UPC: </strong>
                {productData[0]?.itemupc}
              </Typography>
              <Typography variant="body2">
                <strong>Box Size: </strong>
                {productData[0]?.itemboxsize}
              </Typography>
            </FlexWrapper>
            <FlexWrapper justifyContent="space-between">
              <Typography variant="body2">
                <strong>Class: </strong>
                {productData[0]?.itemclass}
              </Typography>
              <Typography variant="body2">
                <strong>Box Size Type: </strong>
                {productData[0]?.itemboxsizetype}
              </Typography>
            </FlexWrapper>
            <FlexWrapper justifyContent="space-between">
              <Typography variant="body2">
                <strong>Pallet Qty: </strong>
                {productData[0]?.itempalletqty}
              </Typography>
              <Typography variant="body2">
                <strong>Case Qty: </strong>
                {productData[0]?.itemcaseqty}
              </Typography>
            </FlexWrapper>
          </Stack>

          <DataGrid
            sx={{ height: "70vh", mt: 1 }}
            columns={[
              {
                field: "binnumber",
                headerName: "Bin",
                type: "string",
                flex: 1.5,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "available",
                headerName: "Available",
                type: "number",
                flex: 1,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "binbrand",
                headerName: "Bin Brand",
                type: "string",
                flex: 1,
                align: "center",
                headerAlign: "center",
              },
            ]}
            rows={productData}
            density="compact"
            getRowId={(row) => row.rownumber}
            slots={{
              footer: () => (
                <GridFooterContainer
                  sx={{
                    px: 0.3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2">
                    <strong>Total:</strong>{" "}
                    {productData?.length
                      ? productData
                          .reduce(
                            (acc, product) =>
                              acc + parseInt(product.available, 10),
                            0
                          )
                          .toLocaleString()
                      : 0}
                  </Typography>

                  <GridFooter />
                </GridFooterContainer>
              ),
            }}
          />
        </>
      )}
    </Container>
  );
};
export default StockLocatorProductLookupPage;
