import { useState } from "react";
import { useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import Loading from "../../Loading";
import IncompleteMoves from "./IncompleteMoves";

const ItemScanInventoryMove = ({
  setItemBinData,
  setMongoData,
  filteredBins,
  setFilteredBins,
}) => {
  const [itemInput, setItemInput] = useState("");
  const [itemError, setItemError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currentNetsuiteLocationIds, username, currentSubsidiary } =
    useSelector((state) => state.user);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (!itemInput) {
        throw new Error("Item Field Cannot Be Empty");
      }

      const response = await axios.post(
        `netsuite/post/inventory-move/item/${itemInput}/bin-data`,
        { nsIds: currentNetsuiteLocationIds }
      );

      const { itemname, iteminternalid, itemclass } = response.data[0];

      //update mongo
      const mongoResponse = await axios.post("inventory-move", {
        item: itemname,
        iteminternalid,
        itemclass,
        username,
        subsidiary: currentSubsidiary,
      });

      setItemError("");
      setItemBinData(response.data);
      setMongoData({
        sourcebin: "",
        qty: "",
        status: mongoResponse.data.status,
        _id: mongoResponse.data._id,
      });
    } catch (error) {
      setItemError(error.response?.data?.msg || error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading message="Loading Bin Data..." />;

  return (
    <Box>
      <Grid
        component="form"
        onSubmit={handleSubmit}
        container
        justifyContent="center"
      >
        <Grid item xs={12} md={10}>
          <TextField
            fullWidth
            required
            autoFocus
            error={Boolean(itemError)}
            label="Item To Move"
            value={itemInput}
            helperText={itemError}
            onChange={(event) => {
              const itemSplit = event.target.value.toUpperCase()?.split("*");
              const item = itemSplit[0];
              setItemInput(item);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={handleSubmit}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {/* Current Incomplete moves */}
      <IncompleteMoves
        setLoading={setLoading}
        setItemBinData={setItemBinData}
        setMongoData={setMongoData}
        filteredBins={filteredBins}
        setFilteredBins={setFilteredBins}
      />
    </Box>
  );
};
export default ItemScanInventoryMove;
