import { useState } from "react";
import { Search } from "@mui/icons-material";
import { Link, useLoaderData, useOutletContext } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../components/FlexWrapper";
import Loading from "../../../components/Loading";
import { useSelector } from "react-redux";

const CycleCountsPage = () => {
  const discrepancyData = useLoaderData();
  const { matches, navigation } = useOutletContext();

  const [search, setSearch] = useState("");
  const [discrepancies, setDiscrepancies] = useState(discrepancyData);
  const user = useSelector((state) => state.user);

  if (!discrepancyData?.length) {
    return (
      <FlexWrapper justifyContent="center">
        <Typography py={2} variant="h4">
          No Pick Discrepancy Cyle Counts Needed
        </Typography>
      </FlexWrapper>
    );
  }

  if (navigation.state === "loading")
    return <Loading message="Loading Bin Data..." />;

  const filteredData = search
    ? discrepancies.filter(
        (item) =>
          item.bin?.includes(search) ||
          item.order?.includes(search) ||
          item.item?.includes(search)
      )
    : discrepancies;

  return (
    <Container maxWidth="lg" sx={{ mb: 2 }}>
      <Typography variant="h4" align="center">
        Pick Discrepancies Cycle Counts
      </Typography>
      <Typography variant="body2" gutterBottom align="right">
        <strong>Total:</strong> {discrepancies.length}
      </Typography>
      <Box pb={1} width={"100%"}>
        <TextField
          fullWidth
          size="small"
          label="Search"
          value={search}
          onChange={(event) => {
            const itemSplit = event.target.value
              ?.toUpperCase()
              ?.trim()
              ?.split("*");
            const item = itemSplit[0];
            setSearch(item);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Stack spacing={2}>
        {filteredData.length ? (
          filteredData?.map((discrepancy) => (
            <Card key={discrepancy._id} style={{ borderRadius: 8 }}>
              <CardContent>
                <FlexWrapper
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Typography></Typography>
                  <Typography
                    variant="h5"
                    gutterBottom
                    textAlign="center"
                    fontWeight="bold"
                  >
                    {discrepancy.bin}
                  </Typography>
                  <Typography variant="caption">
                    Counts: {discrepancy.counts?.length}
                  </Typography>
                </FlexWrapper>
                <Typography variant="body1">
                  <strong>Order: </strong> {discrepancy.order}
                </Typography>
                <Typography variant="body1">
                  <strong>Item: </strong> {discrepancy.item}
                </Typography>
                <Typography variant="body1">
                  <strong>Created By: </strong> {discrepancy.loggedby}
                </Typography>
                <Typography variant="body1">
                  <strong>Created Date: </strong>{" "}
                  {new Date(discrepancy.createdAt)?.toLocaleString()}
                </Typography>
                <Typography variant="body1">
                  <strong>Last Updated: </strong>{" "}
                  {new Date(discrepancy.updatedAt)?.toLocaleString()}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  disabled={Boolean(
                    discrepancy.counts.length &&
                      discrepancy.counts[discrepancy.counts.length - 1]
                        ?.countedby === user?._id
                  )}
                  fullWidth={matches}
                  component={Link}
                  to={`add-cycle-count/${discrepancy._id}`}
                  variant="contained"
                  color="primary"
                >
                  Add Count
                </Button>
              </CardActions>
              <Typography textAlign="center" color="error">
                {discrepancy.counts.length &&
                discrepancy.counts[discrepancy.counts.length - 1]?.countedby ===
                  user?._id
                  ? "You cannot make two counts in a row"
                  : ""}
              </Typography>
            </Card>
          ))
        ) : (
          <Typography variant="h6" textAlign="center" py={2}>
            No Pick Discrepancy Cyle Counts Needed
          </Typography>
        )}
      </Stack>
    </Container>
  );
};

export default CycleCountsPage;
