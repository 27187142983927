import { printPickOrderPalletLabel } from "../../../utils/printer-functions/printPickOrderPalletLabel";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { handlePrintContentLabels } from "../../../utils/printer-functions/printContentLabels";
import { printCustomerLabel } from "../../../utils/printer-functions/printCustomerLabel";
import { Print } from "@mui/icons-material";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Button,
  Divider,
} from "@mui/material";

import PrintOrderLabelsPrintModal from "../../../components/warehouse/labels/print-order-labels/PrintOrderLabelsPrintModal";
import axios from "../../../axios/axios.config";
import PrintBoxLabel from "../../../components/warehouse/labels/print-order-labels/packing/PrintBoxLabel";

const PrintOrderLabelsPage = () => {
  const { currentPrinter } = useSelector((state) => state.printers);
  const { username, currentLocationInternalId } = useSelector(
    (state) => state.user
  );

  const { openAlert } = useGeneralAlert();

  const handlePrintPickLabel = async (order, qty) => {
    try {
      await printPickOrderPalletLabel({
        currentPrinter,
        tranid: order.tranid,
        username,
        brand: order.brandpackaging,
        shippingAddress: order.shippingaddress,
        poNumber: order.ponumber,
        shipMethod: order.shipmethod,
        warehouseNotes: order.warehousenotes,
        alertFn: openAlert,
        title: "Print Pick Label (From Print Order Labels Page)",
        currentLocationInternalId,
        pickQty: order.projectedpickqty || 0,
        relatedtransferorders: order.relatedtransferorders || "",
        mustshipwith: order.mustshipwith,
        trandate: order.trandate,
        qty,
      });
    } catch (error) {
      throw error;
    }
  };

  const handleGetPickData = async (orderNumber) => {
    try {
      const orderResponse = await axios.get(
        `pick-order/get/general/order-number/${orderNumber}`
      );

      if (!orderResponse.data?.length) {
        throw new Error(`No order data found for: ${orderNumber}`);
      }

      return orderResponse.data[0];
    } catch (error) {
      throw error;
    }
  };

  const handleGetPackData = async (orderNumber) => {
    try {
      const orderResponse = await axios.get(
        `pack-process-v2/order/${orderNumber}`
      );

      if (!orderResponse.data?.length) {
        throw new Error(`No order data found for: ${orderNumber}`);
      }

      return orderResponse.data;
    } catch (error) {
      throw error;
    }
  };

  const handlePrintPackContentLabels = async (data, palletNumber) => {
    try {
      const { createdfrom, ponumber, packdetails } = data;

      const palletObj = packdetails.find(
        (obj) => parseInt(obj.palletId) === parseInt(palletNumber)
      );
      //new template header 300dpi
      const templateHeader = `^XA
^MMT^PW1200^LL1800^LS0^POI^LH0,0    
^FT0,125^FB1175,1,0,C,0^A0N,115^FDCONTENTS^FS
^FT0,325^FB1175,1,0,C,0^A0N,225^FD${createdfrom}^FS
^FT0,443^FB1175,1,0,C,0^A0N,85^FDPO#: ${ponumber}^FS
^FT15,554^FB1175,1,0,C,0^A0N,75^FDPALLET: ${
        palletObj?.palletId < 9
          ? `0${palletObj?.palletId}`
          : palletObj?.palletId
      }^FS
^FT0,591^GB1212,1,6,B^FS
^FT0,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT185,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS
^FT628,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT813,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS`;

      const currentPalletItems = palletObj?.items;
      const currentPalletBoxes = palletObj?.boxes;
      let finalItems = [];

      if (currentPalletItems.length) {
        finalItems = [...currentPalletItems];
      }

      if (currentPalletBoxes?.length) {
        currentPalletBoxes.map((boxObj) => {
          if (boxObj.items.length) {
            finalItems = [...finalItems, ...boxObj.items];
          }
        });
      }

      await handlePrintContentLabels({
        startingArr: finalItems,
        templateHeader,
        title: `Print Pack Content Labels from Print Order Labels Page-${createdfrom}`,
        currentPrinter,
      });

      openAlert({
        type: "success",
        message: "Successfully Printed Content Labels",
        duration: 2000,
      });
    } catch (error) {
      throw error;
    }
  };
  //prints cutstomer label
  const handlePrintCustomerLabels = async (data, orderType) => {
    try {
      const order = data[0];

      if (!order?.ucclabeltemplate) {
        throw new Error(
          "Cannot print customer label: Missing ucc label template"
        );
      }

      await printCustomerLabel({
        data: order,
        type: "print",
        currentPrinter,
        title: `Print Customer Label for ${order?.ucclabeltemplate}-${order?.createdfrom}. Order Labels Page.`,
        currentLocationInternalId,
        orderType,
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mb: 1 }}>
      <Typography variant="h4" align="center" gutterBottom pb={1}>
        Print Order Labels
      </Typography>

      <Grid container spacing={4} height="100%" alignItems="stretch">
        {/* Picking Section */}
        <Grid item xs={12} sx={{ height: "100%" }}>
          <Card sx={{ height: "100%", borderTop: "4px solid green" }}>
            <CardContent
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4" textAlign="center" gutterBottom>
                Picking
              </Typography>
              <Box>
                <Typography variant="subtitle1">Order Labels</Typography>
                <Divider flexItem sx={{ mb: 1 }} />
                <PrintOrderLabelsPrintModal
                  buttonTitle=" Print Order Label"
                  getOrderFn={handleGetPickData}
                  printFn={handlePrintPickLabel}
                  openComponent={
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<Print />}
                      fullWidth
                    >
                      Print Order Label
                    </Button>
                  }
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Packing Section */}
        <Grid item xs={12} sx={{ height: "100%" }}>
          <Card sx={{ height: "100%", borderTop: "4px solid blue" }}>
            <CardContent
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography textAlign="center" variant="h4" gutterBottom>
                Packing
              </Typography>
              <Box display="flex" flexDirection="column" gap={3}>
                <Box>
                  <Typography variant="subtitle1">Order Labels</Typography>
                  <Divider flexItem sx={{ mb: 1 }} />
                  <PrintOrderLabelsPrintModal
                    type="pack"
                    buttonTitle="Print Contents Label"
                    getOrderFn={handleGetPackData}
                    printFn={handlePrintPackContentLabels}
                    openComponent={
                      <Button
                        variant="contained"
                        startIcon={<Print />}
                        fullWidth
                      >
                        Print Contents Label
                      </Button>
                    }
                  />
                </Box>
                <Box>
                  <Typography variant="subtitle1">Box Labels</Typography>
                  <Divider flexItem sx={{ mb: 1 }} />
                  <PrintBoxLabel
                    getOrderFn={handleGetPackData}
                    openComponent={
                      <Button
                        startIcon={<Print />}
                        variant="contained"
                        fullWidth
                      >
                        Print Box Label
                      </Button>
                    }
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* UCC Labels Section */}
        <Grid item xs={12} sx={{ height: "100%" }}>
          <Card sx={{ height: "100%", borderTop: "4px solid purple" }}>
            <CardContent
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography textAlign="center" variant="h4" gutterBottom>
                UCC Labels
              </Typography>
              <Box display="flex" flexDirection="column" gap={3}>
                <Box>
                  <Typography variant="subtitle1">Customer Labels</Typography>
                  <Divider flexItem sx={{ mb: 1 }} />
                  <PrintOrderLabelsPrintModal
                    type="customer"
                    buttonTitle="Print Customer Label"
                    getOrderFn={handleGetPackData}
                    printFn={handlePrintCustomerLabels}
                    openComponent={
                      <Button
                        variant="contained"
                        startIcon={<Print />}
                        fullWidth
                        color="secondary"
                      >
                        Print Customer Label
                      </Button>
                    }
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
export default PrintOrderLabelsPage;
