import Resizer from "react-image-file-resizer";

export const resizeFile = (input) => {
  const {
    file, // Is the file of the image which will resized.(required)
    maxWidth = 2500, // Is the maxWidth of the resized new image.(required)
    maxHeight = 2500, // Is the maxHeight of the resized new image.(required)
    compressFormat = "jpeg", // Is the compressFormat of the resized new image.(required)
    quality = 80, // Is the quality of the resized new image.(required)
    rotation = 0, // Is the degree of clockwise rotation to apply to uploaded image.(required)
    responseUriFunc = (uri, resolve) => {
      resolve(uri);
    }, // Is the callBack function of the resized new image URI.(required)
    outputType = "base64", // Is the output type of the resized new image. (not required)
    minWidth = null, // Is the minWidth of the resized new image.(not required)
    minHeight = null, // Is the minHeight of the resized new image.(not required)
  } = input;

  if (!file) {
    throw new Error("File is required");
  }

  if (!parseInt(maxWidth) || !parseInt(maxHeight)) {
    throw new Error("Max width and max height are required");
  }

  if (!compressFormat) {
    throw new Error("Compress format is required");
  }

  if (quality < 0 || quality > 100) {
    throw new Error("Quality must be between 0 and 100");
  }

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      (uri) => responseUriFunc(uri, resolve),
      outputType,
      minWidth,
      minHeight
    );
  });
};
