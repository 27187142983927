import { useEffect } from "react";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getAllItems } from "../../redux/features/procurement/procurementSlice";
import { ALLITEMSCOLUMNS } from "../../utils/columns/procurement/procurementColumns";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AllItems = () => {
  const dispatch = useDispatch();
  const procurement = useSelector((state) => state.procurement);

  useEffect(() => {
    if (procurement?.allItems?.length === 0) {
      dispatch(getAllItems());
    }
  }, []);

  if (procurement?.allItemsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          height: "80vh",
        }}
      >
        <CircularProgress variant="indeterminate" value={10} />
        <Typography variant="body1" color="primary">
          Loading All Items...May Take A Few Minutes
        </Typography>
      </Box>
    );
  }

  if (procurement?.allItemsError) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography color="error">{procurement.procurementError}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Typography component="h1" variant="h3" gutterBottom textAlign="center">
        All Items
      </Typography>
      <>
        <Typography variant="body2" gutterBottom>
          Data Last Updated On:{" "}
          {new Date(procurement?.allItemsLastUpdateDate).toLocaleString()}
        </Typography>

        <DataGrid
          sx={{
            maxHeight: "75vh",
            ".Mui-selected": {
              backgroundColor: "rgb(139 198 255) !important",
            },
          }}
          columns={ALLITEMSCOLUMNS}
          rows={procurement.allItems}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              csvOptions: { fileName: `All-Items-${Date.now()}` },
            },
          }}
          disableColumnSelector
          disableDensitySelector
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[100, 250, 500, 1000]}
          density="compact"
          getRowId={(row) => {
            return row._id;
          }}
        />
      </>
    </Container>
  );
};

export default AllItems;
