import axios from "../../axios/axios.config";
import { printGeneralV2 } from "./printGeneralV2";

//HELPER FUNCTIONS
const generatePrintTemplate = (items, toteid, totalitemsqty) => {
  let template = generateSummaryTemplate(items, toteid, totalitemsqty);
  for (let item of items) {
    template += `^XA^MMT^PW827^LL472^LS0
    ^PON^LH0,0
    ^FT760,40^A0N,34^FD${item.boxsize}^FS
    ^FT0,135^FB827,1,0,C,0^A0N,120,110^FD${
      item.interchange && item.interchange.interchange
        ? item.interchange.interchange
        : item.item
    }^FS
    ^FT0,225^FB827,1,0,C,0^A0N,90,75^FD${
      item.interchange && item.interchange.interchange
        ? item.item
        : item.basenumber === item.item
        ? ""
        : item.basenumber
    }^FS
    ^BY7,2,150^FT85,400^BUN,,Y,N^FD${
      item.interchange && item.interchange.interchange
        ? item.interchange.interchangeUPC
        : item.upc
    }^FS
    ^PQ${item.qty},0,1,Y^XZ`;
  }
  return template;
};

const generateSingleItemPrintTemplate = ({ item, brand, qty }) => {
  if (!item || !brand) throw new Error("Missing required fields: item, brand");

  return `^XA^MMT^PW827^LL472^LS0
    ^PON^LH0,0
    ^FT305,40^FB500,1,0,R,0^A0N,34^FD${`${brand} | ${item.boxsize}`}^FS
    ^FT0,135^FB827,1,0,C,0^A0N,120,110^FD${
      item.interchange && item.interchange.interchange
        ? item.interchange.interchange
        : item.item
    }^FS
    ^FT0,225^FB827,1,0,C,0^A0N,90,75^FD${
      item.interchange && item.interchange.interchange
        ? item.item
        : item.basenumber === item.item
        ? ""
        : item.basenumber
    }^FS
    ^BY7,2,150^FT85,400^BUN,,Y,N^FD${
      item.interchange && item.interchange.interchange
        ? item.interchange.interchangeUPC
        : item.upc
    }^FS
    ^PQ${qty},0,1,Y^XZ`;
};

const generateSummaryTemplate = (items, toteid, totalitemsqty) => {
  const boxSizesObj = {};
  let boxesStr = "BOX SIZE\\&";
  let qtyStr = "QTY\\&";

  for (let item of items) {
    if (!boxSizesObj[item.boxsize]) {
      boxSizesObj[item.boxsize] = 0;
    }
    boxSizesObj[item.boxsize] += item.qty;
  }

  let keys = Object.keys(boxSizesObj).sort();
  for (let key of keys) {
    boxesStr += `BRW-${key}\\&`;
    qtyStr += `${boxSizesObj[key]}\\&`;
  }

  boxesStr = boxesStr.slice(0, -2);
  qtyStr = qtyStr.slice(0, -2);

  let template = `^XA
  ^PON^LH0,0
  ^FO30,10^GB55,440,75^FS
  ^FT90,350^FR^A0B,60^FD${toteid}^FS
  ^FO130,10^GB45,440,3,B,0^FS
  ^FO140,20^FB400,17,5,L,0^A0B,35^FD${boxesStr}^FS
  ^FO140,40^FB125,17,5,C,0^A0B,35^FD${qtyStr}^FS
  ^FO760,10^GB3,440,3^FS
  ^FT800,420^A0B,35^FDTOTAL                   ${totalitemsqty}^FS
  ^PQ1,0,1,Y^XZ`;

  return template;
};

//PRINT TOTE FUNCTIONS
export const printToteItems = async (
  { items, toteid, totalitemsqty },
  currentLocation
) => {
  let printerID = "";
  if (currentLocation === "PAR") {
    printerID = "PNP002";
  }
  if (currentLocation === "NEV") {
    printerID = "PNP004";
  }
  try {
    const res = await axios.get(`printers/${printerID}`);
    const printer = res.data;
    const labelTemplate = generatePrintTemplate(items, toteid, totalitemsqty);
    let printJobUrl = "https://api.printnode.com/printjobs";
    let apiID = process.env.REACT_APP_PRINT_API_ID;
    let body = {
      printerId: printer.printnodeid,
      title: "Tote Items Print: " + toteid,
      contentType: "raw_base64",
      content: btoa(labelTemplate),
      source: "MERN>Tote Manager",
    };

    await axios.post(printJobUrl, body, {
      headers: {
        Authorization: "Basic " + btoa(apiID),
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw error;
  }
};

export const printToteSummaryLabel = async ({
  toteid,
  currentPrinter,
  type = "print",
  items,
  totalitemsqty,
}) => {
  try {
    const template = generateSummaryTemplate(items, toteid, totalitemsqty);

    if (type === "zpl") return template;
    if (type === "print") {
      await printGeneralV2({
        currentPrinter,
        title: "General Print Function",
        template,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const printToteSingleItemLabel = async ({
  brand,
  item,
  currentPrinter,
  type = "print",
  qty = 1,
}) => {
  try {
    const template = generateSingleItemPrintTemplate({ item, brand, qty });

    if (type === "zpl") return template;
    if (type === "print") {
      await printGeneralV2({
        currentPrinter,
        title: "Print Tote Single Item",
        template,
      });
    }
  } catch (error) {
    throw error;
  }
};
