import axiosInstance from "../../../axios/axios.config";

export const toteInspectionLoader = async ({ params }) => {
  const { toteid } = params;
  try {
    const { data } = await axiosInstance.get(`totes/get/mongoid/${toteid}`);

    const items = data?.items;
    const expandedItems = items
      .flatMap((item) => {
        // Generate entries with empty status and unique ID
        const emptyItems = Array.from(
          {
            length:
              item.qty - (item.goodConditionCount + item.scrapConditionCount),
          },
          (_, index) => ({
            item: item.item,
            status: "",
            interchange: item.interchange,
            mongoid: item.mongoid,
            uniqueId: `${item.mongoid}-Empty-${index + 1}`,
          })
        );

        // Create 'good' items based on goodConditionCount with unique IDs
        const goodItems = Array.from(
          { length: item.goodConditionCount },
          (_, index) => ({
            item: item.item,
            status: "Good",
            interchange: item.interchange,
            mongoid: item.mongoid,
            uniqueId: `${item.mongoid}-Good-${index + 1}`,
          })
        );

        // Create 'rust' items based on rustConditionCount with unique IDs
        const scrapItems = Array.from(
          { length: item.scrapConditionCount },
          (_, index) => ({
            item: item.item,
            status: "Scrap",
            interchange: item.interchange,
            mongoid: item.mongoid,
            uniqueId: `${item.mongoid}-Scrap-${index + 1}`,
          })
        );
        // Combine the two arrays
        return [...goodItems, ...scrapItems, ...emptyItems];
      })
      .sort((a, b) => {
        if (a.item < b.item) return -1;
        if (a.item > b.item) return 1;
        return 0;
      });

    return {
      toteid: data?.toteid,
      items,
      expandedItems,
      totalQty: expandedItems?.length,
    };
  } catch (error) {
    return null;
  }
};
