import { useState } from "react";
import { Search } from "@mui/icons-material";
import { Container, IconButton, TextField, Typography } from "@mui/material";
import { useNavigate, useNavigation } from "react-router-dom";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";

const ToteInspectionSearchPage = () => {
  const [input, setInput] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const navigation = useNavigation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.patch(
        `totes/update/tote-inspection/${input}`
      );

      if (!response.data) {
        throw new Error(`Tote ${input} not found`);
      }

      if (response.data?.memo !== "Rust") {
        throw new Error("Tote must be a rust tote");
      }

      navigate(response?.data?._id);
    } catch (error) {
      setError(error?.response?.data?.msg || error.message);
    }
  };

  if (navigation?.state === "loading") {
    return <Loading message="Loading Data..." />;
  }

  return (
    <Container component="form" maxWidth="md" onSubmit={handleSubmit}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Tote Inspection
      </Typography>
      <TextField
        autoFocus
        fullWidth
        label="Enter Tote Number"
        required
        value={input}
        onChange={(event) =>
          setInput(event.target.value?.toUpperCase()?.trim())
        }
        InputProps={{
          endAdornment: (
            <IconButton type="submit">
              <Search />
            </IconButton>
          ),
        }}
        error={Boolean(error)}
        helperText={error}
      />
    </Container>
  );
};
export default ToteInspectionSearchPage;
