import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { UNDERSTOCKANALYSISCOLUMNS } from "../../utils/columns/procurement/procurementColumns";
import { createUnderstockAnalysisData } from "../../redux/features/procurement/procurementSlice";
import { Download } from "@mui/icons-material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import FlexWrapper from "../../components/FlexWrapper";

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const procurementData = useSelector((state) => state.procurement);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer>
      <FlexWrapper justifyContent="space-between" width="100%">
        <FlexWrapper gap={1} alignItems="center">
          <GridToolbarFilterButton />
          <Tooltip title="Download to CSV">
            <IconButton id="csv-button" onClick={handleClick}>
              <Download />
            </IconButton>
          </Tooltip>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "csv-button",
            }}
          >
            <CSVLink
              data={procurementData.understockAnalysis}
              headers={UNDERSTOCKANALYSISCOLUMNS.map((item) => {
                return { label: item.headerName, key: item.field };
              })}
              filename={`understock_analysis_all_${new Date().toDateString()}`}
              style={{ textDecoration: "none" }}
            >
              <MenuItem sx={{ textDecoration: "none" }} onClick={handleClose}>
                <Typography
                  sx={{ color: "black" }}
                  variant="body1"
                  onClick={handleClose}
                >
                  Download All Records
                </Typography>
              </MenuItem>
            </CSVLink>

            <CSVLink
              data={procurementData.understockAnalysis.filter(
                (item) => item.action === "Transfer"
              )}
              headers={UNDERSTOCKANALYSISCOLUMNS.map((item) => {
                return { label: item.headerName, key: item.field };
              })}
              filename={`understock_analysis_transfers_${new Date().toDateString()}`}
              style={{ textDecoration: "none" }}
            >
              <MenuItem sx={{ textDecoration: "none" }} onClick={handleClose}>
                <Typography
                  sx={{ color: "black" }}
                  variant="body1"
                  onClick={handleClose}
                >
                  Download Transfer Records
                </Typography>
              </MenuItem>
            </CSVLink>

            <CSVLink
              data={procurementData.understockAnalysis.filter(
                (item) => item.action === "Buy"
              )}
              headers={UNDERSTOCKANALYSISCOLUMNS.map((item) => {
                return { label: item.headerName, key: item.field };
              })}
              filename={`understock_analysis_buys_${new Date().toDateString()}`}
              style={{ textDecoration: "none" }}
            >
              <MenuItem sx={{ textDecoration: "none" }} onClick={handleClose}>
                <Typography
                  sx={{ color: "black" }}
                  variant="body1"
                  onClick={handleClose}
                >
                  Download Buy Records
                </Typography>
              </MenuItem>
            </CSVLink>
          </Menu>
        </FlexWrapper>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const UnderstockAnalysis = () => {
  const dispatch = useDispatch();
  const procurementData = useSelector((state) => state.procurement);

  useEffect(() => {
    if (procurementData?.understockAnalysis?.length === 0) {
      dispatch(createUnderstockAnalysisData());
    }
  }, []);

  if (procurementData?.understockAnalysisLoading)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          height: "80vh",
        }}
      >
        <CircularProgress variant="indeterminate" />
        <Typography variant="body1" color="primary" textAlign="center">
          Loading Understock Analysis Data...May Take A Few Minutes
        </Typography>
      </Box>
    );

  if (procurementData?.understockAnalysisError)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography color="error">
          {procurementData.procurementError}
        </Typography>
      </Box>
    );

  if (procurementData?.understockAnalysis?.length > 0)
    return (
      <Box px={2}>
        <Typography variant="h3" component="h1" textAlign="center" gutterBottom>
          Understock Analysis
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2">
            Data Last Updated On:{" "}
            {new Date(
              procurementData?.understockAnalysisLastUpdateDate
            ).toLocaleString()}
          </Typography>
          <Box></Box>
        </Box>

        <DataGrid
          sx={{
            maxHeight: "75vh",
            ".Mui-selected": {
              backgroundColor: "rgb(139 198 255) !important",
            },
          }}
          columns={UNDERSTOCKANALYSISCOLUMNS}
          rows={procurementData.filteredUnderstockAnalysis}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          disableColumnMenu
          disableDensitySelector
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[100, 250, 500, 1000]}
          density="compact"
          getRowId={(row) => row.line}
        />
      </Box>
    );
};

export default UnderstockAnalysis;
