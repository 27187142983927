import { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FULLBINSCONSOLIDATIONREPORTCOLUMNS } from "../../../../utils/columns/reports/reportsColumns";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { useSelector } from "react-redux";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";

const FullPalletBinsConsolidationReport = () => {
  const [loading, setLoading] = useState("");
  const [reportData, setReportData] = useState([]);

  const { currentNetsuiteLocationIds } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  useEffect(() => {
    getReportData();
  }, []);

  const getReportData = async () => {
    setLoading("Loading Report...");
    try {
      const response = await axios.get(
        `netsuite/inventory-reports/get/full-pallet-bins-consolidation?nsLocations=${currentNetsuiteLocationIds?.join(
          ","
        )}`
      );

      setReportData(response.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get report data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" textAlign="center">
        Full Pallet Bins Consolidation
      </Typography>

      <DataGrid
        sx={{ height: "85vh" }}
        rows={reportData}
        columns={FULLBINSCONSOLIDATIONREPORTCOLUMNS}
        getRowId={(row) => row.bins}
        slots={{
          toolbar: GridToolbar,
        }}
        disableColumnSelector
        disableDensitySelector
        density="compact"
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: {
              fileName: `full-pallet-bins-consolidation-${Date.now()}`,
            },
          },
        }}
      />
    </Container>
  );
};
export default FullPalletBinsConsolidationReport;
